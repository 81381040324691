




















































import Vue from 'vue'
import InputField from '@/globals/components/form/InputField.vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '@/globals/controllers/Notification'
import { SubjectService } from '@/network/api/subject-service'
import Dropdown from '@/globals/components/form/Dropdown.vue'
import { TaskModel } from '@/globals/models/TaskModel'
import { MaterialModel } from '@/globals/models/MaterialModel'
import TaskModal from '@/views/task/components/task-modal/TaskModal.vue'
import { SubjectModel } from '@/globals/models/SubjectModel'
import MaterialModal from '@/views/material/components/material-modal/MaterialModal.vue'
import { TaskService } from '@/network/api/task-service'
import { MaterialService } from '@/network/api/material-service'
import { ObjectHelper } from '@/globals/helpers/ObjectHelper'
import { CategoryModel } from '@/globals/models/CategoryModel'

export default Vue.extend({
  components: {
    InputField,
    PrimaryModal,
    PrimaryButton,
    Dropdown,
    TaskModal,
    MaterialModal
  },
  props: {
    _subject: {
      type: SubjectModel,
      required: false
    },
    _defaultTasks: {
      type: Array,
      required: false,
      default: () => []
    },
    _defaultMaterials: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      isEssentialsLoaded: false,
      subject: new SubjectModel(),
      defaultTasks: [] as Array<TaskModel>,
      defaultMaterials: [] as Array<MaterialModel>,
      selectedTasks: [] as Array<number>,
      selectedMaterials: [] as Array<number>,
      selectedCategories: [] as Array<number>,
      isCreateTaskModal: false,
      isCreateMaterialModal: false,
      errors: ''
    }
  },
  computed: {
    categories (): Array<CategoryModel> {
      return this.$store.getters['category/categories']
    },
    isSubmitDisabled (): boolean {
      return !this.subject.name
    },
    isEdit (): boolean {
      return this.subject.id !== 0
    },
    filteredTasks (): Array<TaskModel> {
      return this.defaultTasks.filter((defaultTask) => {
        const selected = this.selectedTasks.some(st => st === defaultTask.id)
        if (this.selectedCategories.length === 0 || selected) {
          return true
        }
        return defaultTask.categories.some(c => this.selectedCategories.some(s => s === c.id))
      })
    },
    filteredMaterials (): Array<MaterialModel> {
      return this.defaultMaterials.filter((defaultMaterial) => {
        const selected = this.selectedMaterials.some(st => st === defaultMaterial.id)
        if (this.selectedCategories.length === 0 || selected) {
          return true
        }
        return defaultMaterial.categories.some(c => this.selectedCategories.find(s => s === c.id))
      })
    }
  },
  async beforeMount () {
    if (this._defaultTasks.length) {
      this.defaultTasks = this._defaultTasks as Array<TaskModel>
    } else {
      await this.fetchDefaultTasks()
    }

    if (this._defaultMaterials.length) {
      this.defaultMaterials = this._defaultMaterials as Array<MaterialModel>
    } else {
      await this.fetchDefaultMaterials()
    }

    if (this._subject) {
      this.subject = ObjectHelper.cloneObject(this._subject)

      for (let i = 0; i < this._subject.tasks.length; i++) {
        this.selectedTasks.push(this._subject.tasks[i].id)
      }
      for (let i = 0; i < this._subject.materials.length; i++) {
        this.selectedMaterials.push(this._subject.materials[i].id)
      }
    }

    this.isEssentialsLoaded = true
  },
  methods: {
    async fetchDefaultTasks () {
      await TaskService.getDefaultTasks().then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((taskRaw: any) => {
            const task = new TaskModel().fromResponse(taskRaw)
            this.defaultTasks.push(task)
          })
        }
      })
    },
    async fetchDefaultMaterials () {
      await MaterialService.getDefaultMaterials().then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((materialRaw: any) => {
            const material = new MaterialModel().fromResponse(materialRaw)
            this.defaultMaterials.push(material)
          })
        }
      })
    },

    async submitForm () {
      this.errors = ''
      if (!this.isSubmitDisabled) {
        this.subject.tasks.length = 0
        this.subject.materials.length = 0

        for (let i = 0; i < this.selectedTasks.length; i++) {
          const task = this.defaultTasks.find(t => t.id === this.selectedTasks[i])

          if (task) {
            this.subject.tasks.push(task)
          }
        }
        for (let i = 0; i < this.selectedMaterials.length; i++) {
          const material = this.defaultMaterials.find(m => m.id === this.selectedMaterials[i])

          if (material) {
            this.subject.materials.push(material)
          }
        }

        if (this.isEdit) {
          await this.updateSubject()
        } else {
          await this.createSubject()
        }
      }
    },
    async updateSubject () {
      await SubjectService.updateSubject(this._subject.id, this.subject).then((response: any) => {
        if (response.status === HttpStatus.BAD_REQUEST) {
          this.errors = response.data.detail
        }

        if (response.status === HttpStatus.OK) {
          const subject = new SubjectModel().fromResponse(response.data)
          new Notify().success('Werkzaamheid opgeslagen', subject.name + ' is aangepast')
          this.$emit('updated', subject)
        }
      })
    },
    async createSubject () {
      await SubjectService.createSubject(this.subject).then((response: any) => {
        if (response.status === HttpStatus.BAD_REQUEST) {
          this.errors = response.data.detail
        }

        if (response.status === HttpStatus.OK) {
          const subject = new SubjectModel().fromResponse(response.data)
          new Notify().success('Nieuwe werkzaamheid', subject.name + ' is toegevoegd')
          this.$emit('created', subject)
        }
      })
    },

    onTaskCreated (task: TaskModel) {
      this.defaultTasks.unshift(task)
      this.selectedTasks.unshift(task.id)
      this.isCreateTaskModal = false
    },

    onMaterialCreated (material: MaterialModel) {
      this.defaultMaterials.unshift(material)
      this.selectedMaterials.unshift(material.id)
      this.isCreateMaterialModal = false
    }
  }
})
