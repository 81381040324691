<template>
    <v-tooltip bottom :disabled="disabled">
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <slot></slot>
        </div>
      </template>
      <div class="tooltip--label">{{label}}</div>
    </v-tooltip>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    top: {
      type: Boolean,
      required: false
    },
    bottom: {
      type: Boolean,
      required: false
    },
    left: {
      type: Boolean,
      required: false
    },
    right: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">
.tooltip--label {
  font-size: 12px;
}
</style>
