// initial state
const state = () => ({
  isLoading: false
})

// getters
const getters = {
  isLoading: (state: any) => {
    return state.isLoading
  }
}

// actions
const actions = {
  setIsLoading ({ commit }: any, isLoading: boolean) {
    commit('setIsLoading', isLoading)
  }
}

// mutations
const mutations = {
  setIsLoading (state: any, isLoading: boolean) {
    state.isLoading = isLoading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
