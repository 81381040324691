



















import Vue from 'vue'
import MaterialsTable from './components/materials-table/MaterialsTable.vue'
import MaterialModal from '../components/material-modal/MaterialModal.vue'
import { MaterialModel } from '@/globals/models/MaterialModel'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { MaterialService } from '@/network/api/material-service'
import CategoryModal from '@/globals/components/category-modal/CategoryModal.vue'

export default Vue.extend({
  components: {
    MaterialsTable,
    MaterialModal,
    CategoryModal
  },
  data () {
    return {
      isClient: this.$store.getters['user/isClient'],
      isEssentialsLoaded: false,
      materials: [] as Array<MaterialModel>,
      isNewDossierModal: false,
      isMaterialModal: false,
      selectedMaterial: null as MaterialModel|null,
      isCategoryModal: false
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      await this.fetchDefaultMaterials()
      await this.$store.dispatch('category/fetchCategories')
      this.isEssentialsLoaded = true
    },

    async fetchDefaultMaterials () {
      await MaterialService.getDefaultMaterials().then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((materialRaw: any) => {
            const material = new MaterialModel().fromResponse(materialRaw)
            this.materials.push(material)
          })
        }
      })
    },

    onMaterialCreated (material: MaterialModel) {
      this.materials.unshift(material)
      this.isMaterialModal = false
    },

    onMaterialUpdated (updatedMaterial: MaterialModel) {
      const index = this.materials.findIndex(m => m.id === updatedMaterial.id)

      if (index >= 0) {
        this.materials.splice(index, 1, updatedMaterial)
      }

      this.selectedMaterial = null
      this.isMaterialModal = false
    },

    async deleteMaterial (materialId: number) {
      await MaterialService.deleteMaterial(materialId).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const index = this.materials.findIndex(t => t.id === materialId)
          this.materials.splice(index, 1)
        }
      })
    }
  }
})
