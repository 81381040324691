


































































import Vue from 'vue'
export default Vue.extend({
  props: {
    label: {
      type: String,
      required: false
    },
    forceLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    labelStyle: {
      type: Object,
      required: false
    },
    inputStyle: {
      type: Object,
      required: false
    },
    height: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    icon: {
      type: String,
      required: false
    },
    appendIcon: {
      type: Boolean,
      required: false
    },
    prependIcon: {
      type: Boolean,
      required: false
    },
    step: {
      type: String,
      required: false,
      default: ''
    },
    min: {
      required: false
    },
    max: {
      required: false
    },
    value: {
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    align: {
      type: String,
      required: false,
      default: 'left'
    },
    isPrice: {
      type: Boolean,
      required: false,
      default: false
    },
    showPassword: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    errorModel: {
      type: Array,
      required: false,
      default: () => []
    },
    showLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    autoFocus: {
      type: Boolean,
      required: false,
      default: false
    },
    hideErrors: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      loadingState: this.$store.state.loading.isLoading as boolean,
      isLoading: false,
      isError: false,
      isPasswordShown: false,
      inputType: 'text'
    }
  },
  computed: {
    isNowLoading (): boolean {
      return this.loadingState && this.showLoading
    },
    isDate (): boolean {
      return this.type === 'date'
    }
  },
  beforeMount () {
    this.inputType = this.type
  },
  mounted () {
    if (this.autoFocus) {
      this.focus()
    }
  },
  methods: {
    handleInput (e: any) {
      this.$emit('input', e.target.value)
    },

    isNumeric (str: string) {
      return !isNaN(parseFloat(str))
    },
    str_replaceAll (str: any, search: string, replacement: string) {
      if (typeof str === 'undefined') return str
      return str.replace(new RegExp(search, 'g'), replacement)
    },

    handleBlur (e: any) {
      this.$emit('blur', e.target.value)
    },

    focus () {
      const refs = this.$refs as any
      refs.input.focus()
    }
  }
})
