











import Vue from 'vue'
import ProjectsTable from './components/projects-table/ProjectsTable.vue'
import { ProjectModel } from '@/globals/models/ProjectModel'
import { ProjectService } from '@/network/api/project-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'

export default Vue.extend({
  components: {
    ProjectsTable
  },
  data () {
    return {
      projectStore: this.$store.state.project,
      isClient: this.$store.getters['user/isClient'],
      isEssentialsLoaded: false,
      projects: [] as Array<ProjectModel>,
      isNewDossierModal: false
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      await this.getProjects()
      this.isEssentialsLoaded = true
    },

    async getProjects () {
      await ProjectService.getProjects().then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          this.projects.length = 0
          await response.data.forEach((projectRaw: any) => {
            const project = new ProjectModel().fromResponse(projectRaw)
            this.projects.push(project)
          })
        }
      })
    },

    showNewDossierModal () {
      this.isNewDossierModal = true
    },

    hideNewDossierModal () {
      this.isNewDossierModal = false
    }
  }
})
