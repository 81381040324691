import { ProjectModel } from '@/globals/models/ProjectModel'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { ProjectService } from '@/network/api/project-service'

const state = () => ({
  tab: 0,
  project: null as null|ProjectModel,
  projects: [] as Array<ProjectModel>
})

const getters = {
}

const actions = {
  async setProjects ({ commit }: any) {
    await ProjectService.getProjects().then(async (response: any) => {
      if (response.status === HttpStatus.OK) {
        const projects = [] as Array<ProjectModel>
        response.data.forEach((projectRaw: any) => {
          const project = new ProjectModel().fromResponse(projectRaw)
          projects.push(project)
        })
        commit('setProjects', projects)
      }
    })
  }
}

const mutations = {
  setProjects (state: any, projects: Array<ProjectModel>) {
    state.projects = projects
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
