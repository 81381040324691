export const RouteNames = {
  login: {
    login: 'login'
  },
  project: {
    base: 'project',
    overview: 'project_overview',
    create: 'project_create',
    edit: 'project_edit',
    detail: 'project_detail'
  },
  subject: {
    base: 'subject',
    overview: 'subject_overview'
  },
  task: {
    base: 'task',
    overview: 'task_overview'
  },
  material: {
    base: 'material',
    overview: 'material_overview'
  },
  controller: {
    base: 'controller',
    overview: 'controller_overview'
  },
  mechanic: {
    base: 'mechanic',
    overview: 'mechanic_overview'
  },
  settings: {
    base: 'settings',
    change_password: 'settings_change_password'
  }
}
