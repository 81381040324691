import Vue from 'vue'
import Vuex from 'vuex'
import loading from './modules/loading'
import user from './modules/user'
import project from './modules/project'
import dialog from './modules/dialog'
import category from './modules/category'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    loading,
    user,
    project,
    dialog,
    category
  }
})
