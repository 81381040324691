
































import Vue from 'vue'
import InputField from '@/globals/components/form/InputField.vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { TaskService } from '@/network/api/task-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '@/globals/controllers/Notification'
import { TaskModel } from '@/globals/models/TaskModel'
import { ObjectHelper } from '../../../../globals/helpers/ObjectHelper'
import Dropdown from '@/globals/components/form/Dropdown.vue'
import { CategoryModel } from '@/globals/models/CategoryModel'

export default Vue.extend({
  props: {
    _task: {
      type: TaskModel,
      required: false
    }
  },
  components: {
    InputField,
    PrimaryModal,
    PrimaryButton,
    Dropdown
  },
  data () {
    return {
      task: new TaskModel(),
      isEdit: false,
      errors: '',
      selectedCategories: []
    }
  },
  computed: {
    categories (): Array<CategoryModel> {
      return this.$store.getters['category/categories']
    }
  },
  beforeMount () {
    if (this._task) {
      this.task = ObjectHelper.cloneObject(this._task)
      this.isEdit = true
    }
  },
  methods: {
    async submitForm () {
      this.errors = ''
      if (this.task.name) {
        if (this.isEdit) {
          this.updateTask()
        } else {
          this.createTask()
        }
      }
    },
    async createTask () {
      await TaskService.createTask(this.task).then((response: any) => {
        if (response.status === HttpStatus.BAD_REQUEST) {
          this.errors = response.data.detail
        }

        if (response.status === HttpStatus.OK) {
          const task = new TaskModel().fromResponse(response.data)
          new Notify().success('Nieuwe taak', task.name + ' is toegevoegd')
          this.$emit('created', task)
        }
      })
    },
    async updateTask () {
      await TaskService.updateTask(this.task).then((response: any) => {
        if (response.status === HttpStatus.BAD_REQUEST) {
          this.errors = response.data.detail
        }

        if (response.status === HttpStatus.OK) {
          const task = new TaskModel().fromResponse(response.data)
          new Notify().success('Taak aangepast naar ', task.name)
          this.$emit('updated', task)
        }
      })
    }
  }
})
