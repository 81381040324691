















import Vue from 'vue'
import Navbar from './globals/components/navbar/Navbar.vue'
import ConfirmDialog from './globals/components/confirm-dialog/ConfirmDialogGlobal.vue'

export default Vue.extend({
  components: { Navbar, ConfirmDialog },
  data () {
    return {
      dialogStore: this.$store.state.dialog
    }
  },
  computed: {
    isLoginRoute (): boolean {
      return this.$route.name === 'login'
    }
  },
  mounted () {
    this.dialogStore.confirmDialog = this.$refs.dialog
  }
})
