





























import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import InputField from '@/globals/components/form/InputField.vue'
import { DateHelper } from '@/globals/helpers/DateHelper'

export default Vue.extend({
  props: {
    _controllers: {
      required: true,
      default: () => []
    }
  },
  components: {
    InputField,
    PrimaryButton
  },
  data () {
    return {
      data: [] as Array<any>,
      dateHelper: DateHelper,
      headers: [
        { text: 'Naam', value: 'first_name' },
        { text: 'Email', value: 'email' }
      ],
      search: '',
      isFilterActive: false,
      selectedFilterClient: null as null|number,
      selectedFilterStatus: null as null|number,
      isNewDossierModal: false
    }
  },
  methods: {
  }
})
