import { BaseEnum } from './BaseEnum'

interface Options {
  text: string;
  value: number;
}

export class ProjectStatusEnum extends BaseEnum {
  INCOMPLETE: Options = {
    text: 'Onvoltooid',
    value: 0
  }

  COMPLETE: Options = {
    text: 'Voltooid',
    value: 1
  }

  getAll (): Array<any> {
    return [
      this.INCOMPLETE,
      this.COMPLETE
    ]
  }

  getByValue (value: number): Options {
    const list = this.getAll()

    return super.getItemByValue(list, value)
  }
}
