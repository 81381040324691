var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:{
  'text--btn': true,
  'color--red': _vm.colorRed,
  'color--white': _vm.colorWhite,
  'color--success': _vm.colorSuccess,
  'color--warning': _vm.colorWarning,
  'color--error': _vm.colorError,
  'color--primary': _vm.colorPrimary,
  'color--info': _vm.colorInfo,
  'is--padding': _vm.isPadding,
  'disabled': _vm.disabled || (_vm.loadingState.isLoading && _vm.isLoading),
  'is--label': _vm.label
},style:(_vm.styles),attrs:{"type":_vm.type,"disabled":_vm.disabled || (_vm.loadingState.isLoading && _vm.isLoading)},on:{"click":_vm.handleClick}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingState.isLoading && _vm.isLoading),expression:"loadingState.isLoading && isLoading"}],staticClass:"fas fa-circle-notch fa-spin"}),_vm._v(" "+_vm._s(_vm.label)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }