import { client } from '../client'
import { BaseService } from './base-service'
import { UserModel } from '../../globals/models/UserModel'

export const MechanicService = {
  _apiEndpoint: BaseService.apiEndpoint + 'mechanic',

  async createMechanic (user: UserModel) {
    const url = BaseService.apiEndpoint + 'createmechanic'

    return await client.post(url, user)
  },

  async getMechanics () {
    const url = this._apiEndpoint

    return await client.get(url)
  }
}
