





























































































import Vue from 'vue'
import { ProjectModel } from '@/globals/models/ProjectModel'
import { RouteNames } from '@/router/RouteNames'
import InputField from '@/globals/components/form/InputField.vue'
import Dropdown from '@/globals/components/form/Dropdown.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { ProjectService } from '@/network/api/project-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { SubjectModel } from '@/globals/models/SubjectModel'
import { ControllerService } from '@/network/api/controller-service'
import { UserModel } from '@/globals/models/UserModel'
import { SubjectService } from '@/network/api/subject-service'
import Breadcrumbs from '@/globals/components/breadcrumbs/Breadcrumbs.vue'
import { MechanicService } from '@/network/api/mechanic-service'
import { Notify } from '@/globals/controllers/Notification'
import VuetifyTooltip from '@/globals/components/tooltip/VuetifyTooltip.vue'

export default Vue.extend({
  components: {
    InputField,
    Dropdown,
    PrimaryButton,
    Breadcrumbs,
    VuetifyTooltip
  },
  data () {
    return {
      projectStore: this.$store.state.project,
      dialogStore: this.$store.state.dialog,
      isClient: this.$store.getters['user/isClient'],
      projectId: this.$route.params.id,
      project: new ProjectModel(),
      errors: '',
      isEssentialsLoaded: false,
      defaultSubjects: [] as Array<SubjectModel>,
      controllers: [] as Array<{ value: number; text: string }>,
      mechanics: [] as Array<{ value: number; text: string }>,
      isInputChanged: false,
      isLoadingSubjects: false,
      isLoadingControllers: false,
      isLoadingMechanics: false
    }
  },
  computed: {
    submitDisabled (): boolean {
      let isDisabled = !this.isInputChanged || !this.project.order_id || !this.project.ob_id || !this.project.address || !this.project.city || !this.project.subjects.length || !this.project.controller_user_id as boolean

      if (!this.project.order_id && this.project.ob_id) {
        isDisabled = false
      }
      if (this.project.order_id && !this.project.ob_id) {
        isDisabled = false
      }
      return isDisabled
    },
    isAdmin (): boolean {
      return this.$store.getters['user/isAdmin']
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      if (this.projectStore.project !== null) {
        this.project = this.projectStore.project
      } else {
        await this.getProject()
      }
      await this.fetchDefaultSubjects()
      await this.fetchControllers()
      await this.fetchMechanics()
      this.setSubjects()
      this.isEssentialsLoaded = true
    },
    async getProject () {
      await ProjectService.getProject(this.projectId).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.project = new ProjectModel().fromResponse(response.data)
        }
      })
    },
    async fetchDefaultSubjects () {
      this.isLoadingSubjects = true
      await SubjectService.getDefaultSubjects().then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((subjectRaw: any) => {
            const subject = new SubjectModel().fromResponse(subjectRaw)
            this.defaultSubjects.push(subject)
          })
        }
      })
      this.isLoadingSubjects = false
    },
    async fetchControllers () {
      this.isLoadingControllers = true
      await ControllerService.getControllers().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((controllerRaw: UserModel) => {
            const controller = new UserModel().fromResponse(controllerRaw)
            if (controller.id) {
              this.controllers.push({ value: controller.id, text: controller.getFullName() })
            }
          })
        }
      })
      this.isLoadingControllers = false
    },
    async fetchMechanics () {
      this.isLoadingMechanics = true
      await MechanicService.getMechanics().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((mechanicRaw: UserModel) => {
            const mechanic = new UserModel().fromResponse(mechanicRaw)
            if (mechanic.id) {
              this.mechanics.push({ value: mechanic.id, text: mechanic.getFullName() })
            }
          })
        }
      })
      this.isLoadingMechanics = false
    },
    setSubjects () {
      this.project.subjects.forEach((subject: SubjectModel) => {
        const defaultIndex = this.defaultSubjects.findIndex(s => s.name === subject.name)

        if (defaultIndex >= 0) {
          this.defaultSubjects.splice(defaultIndex, 1, subject)
        }
      })
    },

    async submitForm () {
      this.errors = ''

      if (!this.submitDisabled) {
        await this.updateProject()
      }
    },

    async updateProject () {
      await ProjectService.updateProject(this.project.id, this.project).then((response: any) => {
        if (response.status === HttpStatus.BAD_REQUEST) {
          this.errors = response.data.detail
        }

        if (response.status === HttpStatus.OK) {
          const project = new ProjectModel().fromResponse(response.data)
          this.projectStore.project = project
          this.project = project
          this.setSubjects()
          new Notify().updated()
        }
      })
    },

    async deleteProject () {
      const isDeleteConfirmed = await this.dialogStore.confirmDialog.open({ message: 'Weet je zeker dat je dit project wil verwijderen?', isDeleting: true })

      if (isDeleteConfirmed) {
        await ProjectService.deleteProject(this.projectId).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            this.$router.replace({ name: RouteNames.project.overview })
          }
        })
      }
    },

    goBack () {
      this.$router.back()
    },

    navigateToProjectDetail () {
      this.$router.push({ name: RouteNames.project.detail, params: { id: this.projectId.toString() } })
    }
  }
})
