





















import Vue from 'vue'
export default Vue.extend({
  props: {
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    styles: {
      type: Object,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    colorPrimary: {
      type: Boolean,
      required: false,
      default: false
    },
    colorRed: {
      type: Boolean,
      required: false,
      default: false
    },
    colorWhite: {
      type: Boolean,
      required: false,
      default: false
    },
    colorSuccess: {
      type: Boolean,
      required: false,
      default: false
    },
    colorWarning: {
      type: Boolean,
      required: false,
      default: false
    },
    colorError: {
      type: Boolean,
      required: false,
      default: false
    },
    colorInfo: {
      type: Boolean,
      required: false,
      default: false
    },
    isPadding: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      loadingState: this.$store.state.loading,
      isLoading: false
    }
  },
  watch: {
    loadingState: {
      handler: function () {
        if (!this.loadingState.isLoading) {
          this.isLoading = false
        }
      },
      deep: true
    }
  },
  methods: {
    handleClick () {
      if (this.showLoading) {
        this.isLoading = true
      }

      this.$emit('click')
    }
  }
})
