







































































































import Vue from 'vue'
import ColoredState from '@/globals/components/colored-state/ColoredState.vue'
import { ProjectModel } from '@/globals/models/ProjectModel'
import { ControlModel } from '@/globals/models/ControlModel'
import { SubjectModel } from '@/globals/models/SubjectModel'
import TextArea from '@/globals/components/form/TextArea.vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import TextButton from '@/globals/components/buttons/TextButton.vue'
import PhotoModal from '../../photo-modal/PhotoModal.vue'
import Compressor from 'compressorjs'
import { ControlControlService } from '@/network/api/control-control-service'
import { ControlControlModel } from '@/globals/models/ControlControlModel'
import { ControlControlAttachmentModel } from '@/globals/models/ControlControlAttachmentModel'
import { ProjectService } from '@/network/api/project-service'

export default Vue.extend({
  props: {
    project: {
      type: ProjectModel,
      required: true
    },
    subject: {
      type: SubjectModel,
      required: true
    },
    _taskControls: {
      type: Array,
      required: true
    },
    _materialControls: {
      type: Array,
      required: true
    },
    isEditable: {
      type: Boolean,
      required: true
    }
  },
  components: {
    ColoredState,
    TextArea,
    TextButton,
    PhotoModal
  },
  data () {
    return {
      isLoading: false,
      show: false,
      selectedControl: null as ControlModel|null,
      isAttachmentModalActive: false
    }
  },
  computed: {
    isActionSuccess (): boolean {
      let success = true

      this._taskControls.forEach((taskControl: any) => {
        if (!taskControl.control.control.status) {
          success = false
        }
      })

      this._materialControls.forEach((materialControl: any) => {
        if (!materialControl.control.control.status) {
          success = false
        }
      })

      return success
    },
    isAdmin (): boolean {
      return this.$store.getters['user/isAdmin']
    },
    isController (): boolean {
      return this.$store.getters['user/isController']
    },
    isMechanic (): boolean {
      return this.$store.getters['user/isMechanic']
    }
  },
  beforeMount () {
    this.show = true
  },
  methods: {
    async saveControl (control: ControlModel) {
      this.isLoading = true

      if (!control.id) {
        await this.createControl(control)
      }

      if (control.control.id) {
        await this.updateControlControl(control)
      } else {
        await this.createControlControl(control)
      }

      this.isLoading = false
    },
    async createControl (control: ControlModel) {
      await ProjectService.createControl(this.project.id, control).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const resControl = new ControlModel().fromResponse(response.data)
          control.id = resControl.id
          control.attachments = resControl.attachments
        }
      })
    },
    async createControlControl (control: ControlModel) {
      control.control.control_id = control.id
      await ControlControlService.createControlControl(control.id, control.control).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const controlControl = new ControlControlModel().fromResponse(response.data)
          control.control.id = controlControl.id
          control.control.attachments = controlControl.attachments
        }
      })
    },
    async updateControlControl (control: ControlModel) {
      await ControlControlService.updateControlControl(control.id, control.control)
    },

    async selectFile (control: ControlModel) {
      this.setSelectedControl(control)

      if (!control.control.id) {
        await this.createControlControl(control)
      }

      const element = this.$refs.uploadImage as any
      element.click()
    },
    setSelectedControl (control: ControlModel) {
      this.selectedControl = control
    },

    async onSelectedFile (e: any) {
      const files = e.target.files
      await this.uploadFile(files)

      // Set empty value
      e.target.value = null
    },
    async uploadFile (files: Array<any>) {
      const formData = new FormData()
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const fileCropped = await this.cropFile(file)

        if (fileCropped) {
          const newFile = new File([fileCropped], file.name)
          await formData.append('files', newFile)
        }
      }
      if (this.selectedControl && this.selectedControl.id) {
        await ControlControlService.createControlControlAttachment(this.selectedControl.id, this.selectedControl.control.id, formData).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            response.data.forEach((attachmentRaw: any) => {
              const attachment = new ControlControlAttachmentModel().fromResponse(attachmentRaw)
              if (this.selectedControl) {
                this.selectedControl.control.attachments.push(attachment)
              }
            })
          }
        })
      }
    },
    async cropFile (file: any): Promise<any> {
      const result = await new Promise((resolve, reject) => {
        // eslint-disable-next-line
        new Compressor(file, {
          quality: 0.8,
          maxWidth: 1920,
          maxHeight: 1080,
          success: resolve,
          error: reject
        })
      })

      return result
    },

    openAttachmentsModal (control: ControlModel) {
      this.setSelectedControl(control)
      this.isAttachmentModalActive = true
    },

    removeAttachment (index: number) {
      if (this.selectedControl) {
        this.selectedControl.attachments.splice(index, 1)
      }
    },
    removeControlControlAttachment (index: number) {
      if (this.selectedControl) {
        this.selectedControl.control.attachments.splice(index, 1)
      }
    }
  }
})
