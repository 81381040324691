































import Vue from 'vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import { ControlModel } from '@/globals/models/ControlModel'
import FullPhotoView from '../full-photo-view/FullPhotoView.vue'
import { AttachmentModel } from '@/globals/models/AttachmentModel'
import TextButton from '@/globals/components/buttons/TextButton.vue'
import { ProjectService } from '@/network/api/project-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { ControlControlModel } from '@/globals/models/ControlControlModel'
import { ControlControlService } from '@/network/api/control-control-service'
import { ControlControlAttachmentModel } from '@/globals/models/ControlControlAttachmentModel'

export default Vue.extend({
  components: {
    PrimaryModal,
    FullPhotoView,
    TextButton,
    PrimaryButton
  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    control: {
      type: ControlModel,
      required: true
    },
    controlControl: {
      type: ControlControlModel,
      required: false
    }
  },
  data () {
    return {
      isFullModalActive: false,
      selectedAttachment: null as AttachmentModel|null
    }
  },
  computed: {
    isAdmin (): boolean {
      return this.$store.getters['user/isAdmin']
    },
    isController (): boolean {
      return this.$store.getters['user/isController']
    },
    isMechanic (): boolean {
      return this.$store.getters['user/isMechanic']
    }
  },
  methods: {
    toFullImage (attachment: AttachmentModel) {
      this.setSelectedAttachment(attachment)
      this.isFullModalActive = true
    },
    setSelectedAttachment (attachment: AttachmentModel) {
      this.selectedAttachment = attachment
    },

    async deleteAttachment (attachment: AttachmentModel, index: number) {
      await ProjectService.deleteControlAttachment(this.projectId, attachment.control_id, attachment.id).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.$emit('deleted_attachment', index)
        }
      })
    },

    async deleteControlControlAttachment (attachment: ControlControlAttachmentModel, index: number) {
      await ControlControlService.deleteControlControlAttachment(this.controlControl.control_id, this.controlControl.id, attachment.id).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.$emit('deleted_control_control_attachment', index)
        }
      })
    }
  }
})
