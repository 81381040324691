import { client } from '../client'
import { BaseService } from './base-service'
import { UserModel } from '../../globals/models/UserModel'

export const ControllerService = {
  _apiEndpoint: BaseService.apiEndpoint + 'controller',

  async createController (user: UserModel) {
    const url = BaseService.apiEndpoint + 'createcontroller'

    return await client.post(url, user)
  },

  async getControllers () {
    const url = this._apiEndpoint

    return await client.get(url)
  }
}
