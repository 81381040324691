import Vue from 'vue'
import Vuetify from 'vuetify'
import nl from './lang/nl'

Vue.use(Vuetify)

export default new Vuetify(
  {
    lang: {
      locales: { nl },
      current: 'nl'
    }
  }
)
