import { BaseModel } from '@/globals/models/BaseModel'
import { ControlControlAttachmentModel } from './ControlControlAttachmentModel'

export class ControlControlModel extends BaseModel {
    id = 0
    control_id = 0
    user_id = 0
    note = ''
    status = 0
    attachments = [] as Array<ControlControlAttachmentModel>

    constructor (data?: any) {
      super()
      this.setData(data)
    }

    setData (data?: any) {
      if (data) {
        this.id = data.id
        this.user_id = data.user_id
        this.control_id = data.control_id
        this.note = data.note
        this.status = data.status
        this.attachments = data.attachments
      }
    }

    getErrorEntity () {
      const errorModel = super.generateErrorEntity(this) as any

      return errorModel
    }

    fromResponse (data: any) {
      this.setData(data)

      this.attachments = []
      data.attachments.forEach((attachmentRaw: any) => {
        const attachment = new ControlControlAttachmentModel().fromResponse(attachmentRaw)
        this.attachments.push(attachment)
      })

      return this
    }
}
