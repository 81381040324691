



























import Vue from 'vue'

export default Vue.extend({
  props: {
    hideDeleteBtn: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isOpen: false
    }
  }
})
