


















import Vue from 'vue'
import SubjectsTable from './components/subjects-table/SubjectsTable.vue'
import SubjectModal from '../components/subject-modal/SubjectModal.vue'
import { SubjectModel } from '@/globals/models/SubjectModel'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { SubjectService } from '@/network/api/subject-service'
import { Notify } from '@/globals/controllers/Notification'
import { TaskService } from '@/network/api/task-service'
import { TaskModel } from '@/globals/models/TaskModel'
import { MaterialModel } from '@/globals/models/MaterialModel'
import { MaterialService } from '@/network/api/material-service'
import { ObjectHelper } from '@/globals/helpers/ObjectHelper'

export default Vue.extend({
  components: {
    SubjectsTable,
    SubjectModal
  },
  data () {
    return {
      isClient: this.$store.getters['user/isClient'],
      isEssentialsLoaded: false,
      subjects: [] as Array<SubjectModel>,
      defaultTasks: [] as Array<TaskModel>,
      defaultMaterials: [] as Array<MaterialModel>,
      isNewDossierModal: false,
      isSubjectModal: false,
      selectedSubject: null as null|SubjectModel
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      await this.fetchSubjects()
      await this.fetchDefaultTasks()
      await this.fetchDefaultMaterials()
      await this.$store.dispatch('category/fetchCategories')
      this.isEssentialsLoaded = true
    },

    async fetchSubjects () {
      await SubjectService.getDefaultSubjects().then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((subjectRaw: any) => {
            const subject = new SubjectModel().fromResponse(subjectRaw)
            this.subjects.push(subject)
          })
        }
      })
    },
    async fetchDefaultTasks () {
      await TaskService.getDefaultTasks().then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((taskRaw: any) => {
            const task = new TaskModel().fromResponse(taskRaw)
            this.defaultTasks.push(task)
          })
        }
      })
    },
    async fetchDefaultMaterials () {
      await MaterialService.getDefaultMaterials().then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((materialRaw: any) => {
            const material = new MaterialModel().fromResponse(materialRaw)
            this.defaultMaterials.push(material)
          })
        }
      })
    },

    onSubjectUpdated (subject: SubjectModel) {
      const oldIndex = this.subjects.findIndex(s => s.id === subject.id)

      if (oldIndex !== null) {
        this.subjects.splice(oldIndex, 1, subject)
        this.isSubjectModal = false
      }
    },

    onSubjectCreated (subject: SubjectModel) {
      this.subjects.unshift(subject)
      this.isSubjectModal = false
    },

    async deleteSubject (subjectId: number, notify = true) {
      await SubjectService.deleteSubject(subjectId).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const subject = this.subjects.find(s => s.id === subjectId)
          if (subject) {
            const index = this.subjects.indexOf(subject)
            if (notify) {
              new Notify().success('Verwijderd', subject.name + ' is verwijderd')
            }
            this.subjects.splice(index, 1)
          }
        }
      })
    },

    duplicateSubject (subject: SubjectModel) {
      const duplicate = ObjectHelper.cloneObject(subject) as SubjectModel
      duplicate.id = 0
      duplicate.name = subject.name + ' (kopie)'
      this.selectedSubject = duplicate
      this.isSubjectModal = true
    }
  }
})
