import { BaseModel } from '@/globals/models/BaseModel'
import { AttachmentModel } from './AttachmentModel'
import { ControlControlModel } from './ControlControlModel'
export class ControlModel extends BaseModel {
    id = 0
    control_day_id = null as number|null
    material_id = null as number|null
    task_id = null as number|null
    note = ''
    status = 0
    attachments = [] as Array<AttachmentModel>
    control = new ControlControlModel()

    constructor (data?: any) {
      super()
      this.setData(data)
    }

    setData (data?: any) {
      if (data) {
        this.id = data.id
        this.control_day_id = data.control_day_id
        this.material_id = data.material_id
        this.task_id = data.task_id
        this.note = data.note
        this.status = data.status
        this.attachments = data.attachments
        this.control = data.control ?? new ControlControlModel()
      }
    }

    getErrorEntity () {
      const errorModel = super.generateErrorEntity(this) as any

      return errorModel
    }

    fromResponse (data: any) {
      this.setData(data)

      this.attachments = []
      data.attachments.forEach((attachmentRaw: any) => {
        const attachment = new AttachmentModel().fromResponse(attachmentRaw)
        this.attachments.push(attachment)
      })

      if (data.control) {
        this.control = new ControlControlModel().fromResponse(data.control)
      }

      return this
    }
}
