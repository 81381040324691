
































import Vue from 'vue'
import { DateHelper } from '@/globals/helpers/DateHelper'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '@/globals/controllers/Notification'
import VuetifyTooltip from '@/globals/components/tooltip/VuetifyTooltip.vue'
import AttachmentMenu from './attachment-menu/AttachmentMenu.vue'
import { ProjectAttachmentModel } from '@/globals/models/ProjectAttachmentModel'
import { ProjectModel } from '@/globals/models/ProjectModel'
import { ProjectService } from '@/network/api/project-service'

export default Vue.extend({
  components: {
    VuetifyTooltip,
    AttachmentMenu
  },
  data () {
    return {
      projectStore: this.$store.state.project,
      userStore: this.$store.state.user,
      dateHelper: DateHelper,
      newComment: '',
      isNewAttachmentLoading: false
    }
  },
  computed: {
    project: {
      get (): ProjectModel {
        return this.projectStore.project ?? new ProjectModel()
      },
      set (project: ProjectModel) {
        this.projectStore.project = project
        return project
      }
    },
    isAdmin (): boolean {
      return this.$store.getters['user/isAdmin']
    },
    isController (): boolean {
      return this.$store.getters['user/isController']
    },
    isMechanic (): boolean {
      return this.$store.getters['user/isMechanic']
    }
  },
  methods: {
    selectAttachment () {
      const element = this.$refs.uploadAttachment as any
      element.click()
    },
    async onSelectedFile (e: any) {
      const file = e.target.files[0]

      if (file && this.validateFileSize(file)) {
        this.isNewAttachmentLoading = true
        await this.uploadAttachment(file)
        this.isNewAttachmentLoading = false
      }
      e.target.value = null
    },
    validateFileSize (file: any) {
      const size = file.size / 1024 / 1024 // in MB

      if (size > 5) {
        alert('Document mag niet groter zijn dan 5 MB')
      } else {
        return true
      }

      return false
    },

    async uploadAttachment (file: any) {
      const formData = new FormData()
      formData.append('files', file)
      await ProjectService.createProjectAttachment(this.project.id, formData).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const attachment = new ProjectAttachmentModel().fromResponse(response.data[0])
          this.project.attachments.push(attachment)
          new Notify().success('Gelukt!', 'de bijlage is toegevoegd')
        }
      })
    },

    async deleteAttachment (id: number, index: number) {
      await ProjectService.deleteProjectAttachment(this.project.id, id).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.project.attachments.splice(index, 1)
        }
      })
    },

    openAttachment (url: string) {
      window.open(url, '_blank')
    }
  }
})
