import { client } from '../client'
import { BaseService } from './base-service'

export const SubjectService = {
  _apiEndpoint: BaseService.apiEndpoint + 'subject',

  async getDefaultSubjects () {
    const url = this._apiEndpoint + '/defaults'

    return await client.get(url)
  },

  async createSubject (data: any) {
    const url = this._apiEndpoint

    return await client.post(url, data)
  },

  async updateSubject (id: number, data: any) {
    const url = this._apiEndpoint + '/' + id

    return await client.put(url, data)
  },

  async deleteSubject (id: number) {
    const url = this._apiEndpoint + '/' + id

    return await client.delete(url)
  }
}
