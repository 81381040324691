import { BaseModel } from '@/globals/models/BaseModel'

export class ControlControlAttachmentModel extends BaseModel {
    id = 0
    control_id = 0
    url = ''
    file_name = ''

    constructor (data?: any) {
      super()
      this.setData(data)
    }

    setData (data?: any) {
      if (data) {
        this.id = data.id
        this.control_id = data.control_id
        this.url = data.url
        this.file_name = data.file_name
      }
    }

    getErrorEntity () {
      const errorModel = super.generateErrorEntity(this) as any

      return errorModel
    }

    fromResponse (data: any) {
      this.setData(data)

      return this
    }
}
