import { RoleEnum } from '@/globals/enums/RoleEnum'
import { UserModel } from '@/globals/models/UserModel'

// initial state
const state = () => ({
  firstName: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') ?? '').first_name : '',
  lastName: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') ?? '').last_name : '',
  email: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') ?? '').email : '',
  role: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') ?? '').role : ''
})

// getters
const getters = {
  isLoading: (state: any) => {
    return state.isLoading
  },
  isAdmin: (state: any) => {
    return state.role === RoleEnum.Admin
  },
  isController: (state: any) => {
    return state.role === RoleEnum.Controller
  },
  isMechanic: (state: any) => {
    return state.role === RoleEnum.Mechanic
  }
}

// actions
const actions = {
  setUser ({ commit }: any, user: UserModel) {
    commit('setUser', user)
  }
}

// mutations
const mutations = {
  setUser (state: any, user: UserModel) {
    localStorage.setItem('user', JSON.stringify(user))
    state.firstName = user.first_name
    state.lastName = user.last_name
    state.email = user.email
    state.role = user.role
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
