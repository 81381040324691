




































































































import Vue from 'vue'
import ColoredState from '@/globals/components/colored-state/ColoredState.vue'
import { ProjectModel } from '@/globals/models/ProjectModel'
import { ControlModel } from '@/globals/models/ControlModel'
import { SubjectModel } from '@/globals/models/SubjectModel'
import { ControlDayModel } from '@/globals/models/ControlDayModel'
import { TaskModel } from '@/globals/models/TaskModel'
import { MaterialModel } from '@/globals/models/MaterialModel'
import { ProjectService } from '@/network/api/project-service'
import TextArea from '@/globals/components/form/TextArea.vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import TextButton from '@/globals/components/buttons/TextButton.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import PhotoModal from '../../photo-modal/PhotoModal.vue'
import { AttachmentModel } from '@/globals/models/AttachmentModel'
import Compressor from 'compressorjs'

export default Vue.extend({
  props: {
    project: {
      type: ProjectModel,
      required: true
    },
    controlDay: {
      type: ControlDayModel,
      required: true
    },
    subject: {
      type: SubjectModel,
      required: true
    },
    isEditable: {
      type: Boolean,
      required: true
    }
  },
  components: {
    ColoredState,
    TextArea,
    TextButton,
    PrimaryButton,
    PhotoModal
  },
  data () {
    return {
      isLoading: false,
      show: false,
      taskControls: [] as Array<{ task: TaskModel; control: ControlModel }>,
      materialControls: [] as Array<{ material: MaterialModel; control: ControlModel }>,
      selectedControl: null as ControlModel|null,
      isAttachmentModalActive: false
    }
  },
  computed: {
    isSubjectControlled (): boolean {
      let isControlled = false

      for (let i = 0; i < this.taskControls.length; i++) {
        if (this.taskControls[i].control.id) {
          isControlled = true
          break
        }
      }
      if (!isControlled) {
        for (let i = 0; i < this.materialControls.length; i++) {
          if (this.materialControls[i].control.id) {
            isControlled = true
            break
          }
        }
      }
      return isControlled
    },

    isSubjectSuccess (): boolean {
      let success = true

      this.taskControls.forEach((control) => {
        if (!control.control.status) {
          success = false
        }
      })

      this.materialControls.forEach((control) => {
        if (!control.control.status) {
          success = false
        }
      })

      return success
    }
  },
  beforeMount () {
    this.setControls()

    if (this.isSubjectControlled) {
      this.show = true
    }
  },
  methods: {
    setControls () {
      this.subject.tasks.forEach((task: TaskModel) => {
        let control = this.getControlByTaskId(task.id)

        if (!control) {
          control = new ControlModel()
          control.control_day_id = this.controlDay.id
          this.controlDay.controls.push(control)
        }

        control.task_id = task.id
        this.taskControls.push({ task: task, control: control })
      })

      this.subject.materials.forEach((material: MaterialModel) => {
        let control = this.getControlByMaterialId(material.id)

        if (!control) {
          control = new ControlModel()
          control.control_day_id = this.controlDay.id
          this.controlDay.controls.push(control)
        }

        control.material_id = material.id
        this.materialControls.push({ material: material, control: control })
      })
    },
    getControlByTaskId (taskId: number): ControlModel|null {
      const control = this.controlDay.controls.find(c => c.task_id === taskId)
      return control ?? null
    },
    getControlByMaterialId (materialId: number): ControlModel|null {
      const control = this.controlDay.controls.find(c => c.material_id === materialId)
      return control ?? null
    },

    async saveControl (control: ControlModel) {
      this.isLoading = true

      if (control.id) {
        await this.updateControl(control)
      } else {
        await this.createControl(control)
      }

      this.isLoading = false
    },
    async createControl (control: ControlModel) {
      await ProjectService.createControl(this.project.id, control).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const resControl = new ControlModel().fromResponse(response.data)
          control.id = resControl.id
          control.attachments = resControl.attachments
        }
      })
    },
    async updateControl (control: ControlModel) {
      await ProjectService.updateControl(this.project.id, control).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const resControl = new ControlModel().fromResponse(response.data)
          control.id = resControl.id
          control.attachments = resControl.attachments
        }
      })
    },

    async selectFile (control: ControlModel) {
      this.setSelectedControl(control)

      if (!control.id) {
        await this.createControl(control)
      }

      const element = this.$refs.uploadImage as any
      element.click()
    },
    setSelectedControl (control: ControlModel) {
      this.selectedControl = control
    },

    async onSelectedFile (e: any) {
      const files = e.target.files
      await this.uploadFile(files)

      // Set empty value
      e.target.value = null
    },
    async uploadFile (files: Array<any>) {
      const formData = new FormData()
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const fileCropped = await this.cropFile(file)
        if (fileCropped) {
          const newFile = new File([fileCropped], file.name)
          await formData.append('files', newFile)
        }
      }
      if (this.selectedControl && this.selectedControl.id) {
        await ProjectService.createControlAttachment(this.project.id, this.selectedControl.id, formData).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            response.data.forEach((attachmentRaw: any) => {
              const attachment = new AttachmentModel().fromResponse(attachmentRaw)
              if (this.selectedControl) {
                this.selectedControl.attachments.push(attachment)
              }
            })
          }
        })
      }
    },
    async cropFile (file: any): Promise<any> {
      const result = await new Promise((resolve, reject) => {
        // eslint-disable-next-line
        new Compressor(file, {
          quality: 0.8,
          maxWidth: 1920,
          maxHeight: 1080,
          success: resolve,
          error: reject
        })
      })

      return result
    },

    openAttachmentsModal (control: ControlModel) {
      this.setSelectedControl(control)
      this.isAttachmentModalActive = true
    },

    removeAttachment (index: number) {
      if (this.selectedControl) {
        this.selectedControl.attachments.splice(index, 1)
      }
    }
  }
})
