
































import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import InputField from '@/globals/components/form/InputField.vue'
import { DateHelper } from '@/globals/helpers/DateHelper'
import { TaskModel } from '@/globals/models/TaskModel'

export default Vue.extend({
  props: {
    _tasks: {
      required: true,
      default: () => []
    }
  },
  components: {
    InputField,
    PrimaryButton
  },
  data () {
    return {
      data: [] as Array<any>,
      dateHelper: DateHelper,
      headers: [
        { text: 'Naam', value: 'name', width: '40%' },
        { text: 'Categorieën', value: 'categoriesString', width: '40%' },
        { text: '', value: 'actions', sortable: false, align: 'end' }
      ],
      search: '',
      isFilterActive: false,
      selectedFilterClient: null as null|number,
      selectedFilterStatus: null as null|number,
      isNewDossierModal: false
    }
  },
  watch: {
    _tasks () {
      this.setData()
    }
  },
  beforeMount () {
    this.setData()
  },
  methods: {
    setData () {
      this.data.length = 0
      this._tasks.forEach((task: TaskModel) => {
        this.addTableItem(task)
      })
    },
    addTableItem (task: TaskModel) {
      let categoriesString = ''

      task.categories.forEach(category => {
        if (categoriesString) {
          categoriesString = categoriesString + ', '
        }
        categoriesString = categoriesString + category.name
      })
      const dataItem = {
        id: task.id,
        name: task.name,
        categoriesString: categoriesString,
        task: task
      }

      this.data.push(dataItem)
    }
  }
})
