import moment from 'moment'

export const DateHelper = {
  toLocaleDateString (date: Date|string): string {
    if (typeof date === 'string' || date instanceof String) {
      date = this.toDate(date)
    }

    const options = { year: 'numeric', month: 'short', day: 'numeric' } as any

    // return moment(date, 'YYYY-MM-DD').toLocaleString()
    return date.toLocaleDateString(undefined, options)
  },
  toDate (date: Date|string): Date {
    return moment(date).toDate()
  },
  getTimeFromDateTime (dateTime: Date): string {
    return dateTime.getHours() + ':' + dateTime.getMinutes()
  },
  toUnix (dateTime: Date|string): number {
    return moment(dateTime).unix() // dateTime.getTime() / 1000
  },
  toDateTime (date: Date|string): string {
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  },
  toUTCDateTime (date: Date|string): string {
    return moment(date).utc().format('YYYY-MM-DD HH:mm:ss')
  },
  toDateString (date: Date|string) {
    return moment(date).format('YYYY-MM-DD')
  },
  toUTCDateString (date: Date|string) {
    return moment(date).utc().format('YYYY-MM-DD')
  },
  fromNow (date: any) {
    return moment(date).locale('nl').fromNow()
  },
  toDateZeroTime (date: Date|string): Date {
    return moment(this.toDateString(date)).toDate()
  },
  getNewDate () {
    return new Date(new Date() + 'Z')
  }
}
