import { client } from '../client'
import { BaseService } from './base-service'
import { UserModel } from '../../globals/models/UserModel'

export const UserService = {
  _apiEndpoint: BaseService.apiEndpoint + 'user/',

  async getUserSelf () {
    const url = this._apiEndpoint + 'self'

    return await client.get(url)
  },

  async getUser () {
    const url = this._apiEndpoint

    return await client.get(url)
  },

  async updateUser (user: UserModel) {
    const url = BaseService.apiEndpoint + 'user'

    return await client.put(url, user)
  },

  async changePassword (oldPassword: string, newPassword: string) {
    const url = BaseService.apiEndpoint + 'changepassword'

    return await client.post(url, { old_password: oldPassword, password: newPassword })
  },

  async changeUserPassword (userId: number, password: string) {
    const url = BaseService.apiEndpoint + 'admin/changepassword'

    return await client.post(url, { user_id: userId, password: password })
  }
}
