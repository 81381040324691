import { BaseModel } from '@/globals/models/BaseModel'
import { TaskModel } from './TaskModel'
import { MaterialModel } from './MaterialModel'
export class SubjectModel extends BaseModel {
    id = 0
    name = ''
    project_id = 0
    status = 0
    tasks = [] as Array<TaskModel>
    materials = [] as Array<MaterialModel>

    constructor (data?: any) {
      super()
      this.setData(data)
    }

    setData (data?: any) {
      if (data) {
        this.id = data.id
        this.name = data.name
        this.project_id = data.project_id
        this.status = data.status
        this.tasks = data.tasks
        this.materials = data.materials
      }
    }

    getErrorEntity () {
      const errorModel = super.generateErrorEntity(this) as any

      return errorModel
    }

    fromResponse (data: any) {
      this.setData(data)

      this.tasks = []
      data.tasks.forEach((tasksRaw: any) => {
        const task = new TaskModel().fromResponse(tasksRaw)
        this.tasks.push(task)
      })

      this.materials = []
      data.materials.forEach((materialRaw: any) => {
        const material = new MaterialModel().fromResponse(materialRaw)
        this.materials.push(material)
      })
      return this
    }
}
