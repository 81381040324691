













import Vue from 'vue'
import ControllersTable from './components/controllers-table/ControllersTable.vue'
import CreateControllerModal from '../components/create-controller-modal/CreateControllerModal.vue'
import { UserModel } from '@/globals/models/UserModel'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { ControllerService } from '@/network/api/controller-service'
import UpdateControllerModal from '../components/update-controller-modal/UpdateControllerModal.vue'

export default Vue.extend({
  components: {
    ControllersTable,
    CreateControllerModal,
    UpdateControllerModal
  },
  data () {
    return {
      isClient: this.$store.getters['user/isClient'],
      isEssentialsLoaded: false,
      controllers: [] as Array<UserModel>,
      isNewDossierModal: false,
      isCreateControllerModal: false,
      selectedController: null as null|UserModel,
      isUpdateControllerModal: false
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      await this.getControllers()
      this.isEssentialsLoaded = true
    },

    async getControllers () {
      await ControllerService.getControllers().then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          this.controllers = []
          response.data.forEach((userRaw: any) => {
            const user = new UserModel().fromResponse(userRaw)
            this.controllers.push(user)
          })
        }
      })
    },

    onControllerCreated (controller: UserModel) {
      this.controllers.unshift(controller)
      this.isCreateControllerModal = false
    },

    openUpdateModal (controller: UserModel) {
      this.selectedController = controller
      this.isUpdateControllerModal = true
    },

    onControllerUpdated (controller: UserModel) {
      const controllerIndex = this.controllers.findIndex(c => c.id === controller.id)

      if (controllerIndex !== null) {
        this.controllers[controllerIndex].first_name = controller.first_name
        this.controllers[controllerIndex].last_name = controller.last_name
        this.controllers[controllerIndex].email = controller.email
      }

      this.isUpdateControllerModal = false
    }
  }
})
