<template>
      <div class="text-area__wrapper">
        <label>{{ label }}</label>
        <textarea
          :style="styleObject"
          :class="errorModel.length ? 'form--textarea validation--error' : 'form--textarea'" :disabled="disabled"
          :placeholder="placeholder"
          v-on:input="$emit('input', $event.target.value)"
          v-on:blur="$emit('blur', $event.target.value)"
          :value="value"
        />
        <ul v-if="errorModel.length" class="form__validation">
          <li v-for="error in errorModel" :key="error.id">{{ error }}</li>
        </ul>
      </div>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      required: false
    },
    errorModel: {
      type: Array,
      required: false,
      default: () => []
    },
    styleObject: {
      type: Object,
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/_globals';

.text-area__wrapper {
  display: flex;
  flex-direction: column;
}

.form--textarea {
  padding: 6px 12px;
  background-color: white;
  border: 1px solid rgb(213, 217, 220);
  border-radius: 4px;
  width: 100%;
  font-size: 14px;

  &:focus {
    outline: none;
    border: 1px solid rgb(175, 182, 255);
    box-shadow: 0 0 4px 0 rgb(175, 182, 255);
  }

  &:disabled {
    color: rgba(35, 37, 59, 0.4);
    border: 1px solid rgba(22, 23, 45, 0.05);
  }

  &.validation--error {
    border: map-get($state-colors, 'error');
    box-shadow: 0 0 4px 0 map-get($state-colors, 'error');
    color: map-get($state-colors, 'error');
  }

  &.append--icon {
    padding-left: 35px;
  }

  &.prepend--icon {
    padding-right: 35px;
  }

  &.height--large {
    height: 40px;
  }
}
</style>
