export class Notify {
  title = ''
  subtitle = ''
  isError = false
  duration = 3000

  create (title: string, subtitle: string, error = false) {
    this.title = title
    this.subtitle = subtitle
    this.isError = error

    this.newNotification()
  }

  success (title: string, subtitle: string) {
    this.title = title
    this.subtitle = subtitle

    this.newNotification()
  }

  error (title: string, subtitle: string) {
    this.title = title
    this.subtitle = subtitle
    this.isError = true

    this.newNotification()
  }

  updated () {
    this.title = 'Wijzigingen opgeslagen'
    this.subtitle = 'Al je wijzigingen zijn succesvol opgeslagen'
    this.newNotification()
  }

  deleted () {
    this.title = 'Gelukt!'
    this.subtitle = 'Het item is verwijderd'
    this.newNotification()
  }

  entityError () {
    this.title = 'Niet alle velden zijn juist ingevuld'
    this.subtitle = 'Vul de velden in'
    this.isError = true
    this.newNotification()
  }

  newNotification () {
    const notification = this.notificationTemplate()
    const notifications = document.getElementById('notifications')

    if (notifications) {
      notifications.appendChild(notification)
    }

    setTimeout(function () {
      notification.classList.remove('slide-in')
      notification.classList.add('slide-out')

      setTimeout(function () {
        const notifications = document.getElementById('notifications')

        if (notifications) {
          notifications.removeChild(notification)
        }
      }, 500)
    }, this.duration)
  }

  notificationTemplate () {
    const div = document.createElement('div')
    div.className = 'notification'
    div.classList.add('slider')
    div.classList.add('slide-in')
    div.classList.add('state--' + (this.isError ? 'error' : 'success'))
    div.innerHTML = `
          <i class="far fa-check-circle"></i>
          <div>
              <div v-if="title" class="notification--title">${this.title}</h3>
              <div v-if="subtitle" class="notification--subtitle">${this.subtitle}</span>
          </div>
    `

    return div
  }
}
