












































































































import Vue from 'vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { ProjectModel } from '@/globals/models/ProjectModel'
import { ProjectService } from '@/network/api/project-service'
import ProjectHeader from './components/project-header/ProjectHeader.vue'
import ControlCard from './components/control-card/ControlCard.vue'
import { ControlDayModel } from '@/globals/models/ControlDayModel'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import BlankSlate from '@/globals/components/blank-slates/BlankSlate.vue'
import { RouteNames } from '@/router/RouteNames'
import ActionBullets from './components/action-bullets/ActionBullets.vue'
import Breadcrumbs from '@/globals/components/breadcrumbs/Breadcrumbs.vue'
import TextArea from '@/globals/components/form/TextArea.vue'
import Attachments from './components/attachments/Attachments.vue'

export default Vue.extend({
  components: {
    ProjectHeader,
    ControlCard,
    PrimaryButton,
    BlankSlate,
    ActionBullets,
    Breadcrumbs,
    TextArea,
    Attachments
  },
  data () {
    return {
      projectStore: this.$store.state.project,
      dialogStore: this.$store.state.dialog,
      isEssentialsLoaded: false,
      projectId: this.$route.params.id,
      tab: 0
    }
  },
  computed: {
    project: {
      get (): ProjectModel {
        return this.projectStore.project ?? new ProjectModel()
      },
      set (project: ProjectModel) {
        this.projectStore.project = project
        return project
      }
    },
    isAdmin (): boolean {
      return this.$store.getters['user/isAdmin']
    },
    isController (): boolean {
      return this.$store.getters['user/isController']
    },
    isMechanic (): boolean {
      return this.$store.getters['user/isMechanic']
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      await this.getProject()
      this.isEssentialsLoaded = true
    },

    async getProject () {
      await ProjectService.getProject(this.projectId).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.project = new ProjectModel().fromResponse(response.data)
        }
      })
    },

    async updateProject () {
      await ProjectService.updateProject(this.project.id, this.project)
    },

    async createControlDay () {
      const controlDay = new ControlDayModel()
      controlDay.project_id = this.project.id

      if (this.project.control_days.length > 0) {
        const previous = this.project.control_days[0]
        if (previous.isControlled()) {
          previous.controls.forEach(control => {
            if (control.id !== 0) {
              controlDay.controls.push(control)
            }
          })
        }
      }

      await ProjectService.createControlDay(this.projectId, controlDay).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const controlDay = new ControlDayModel().fromResponse(response.data)
          if (this.project) {
            this.project.control_days.unshift(controlDay)
          }
        }
      })
    },

    deleteControlDay (index: number) {
      if (this.project) {
        this.project.control_days.splice(index, 1)
      }
    },

    async deleteProject () {
      const isDeleteConfirmed = await this.dialogStore.confirmDialog.open({ message: 'Weet je zeker dat je dit project wil verwijderen?', isDeleting: true })

      if (isDeleteConfirmed) {
        await ProjectService.deleteProject(this.projectId).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            this.$router.push({ name: RouteNames.project.overview })
          }
        })
      }
    }
  }
})
