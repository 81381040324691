






















































import Vue from 'vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { CategoryService } from '@/network/api/category-service'
import { CategoryModel } from '@/globals/models/CategoryModel'
import PrimaryModal from '../modals/PrimaryModal.vue'
import InputField from '../form/InputField.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import { Notify } from '@/globals/controllers/Notification'

export default Vue.extend({
  components: { PrimaryModal, InputField, PrimaryButton },
  data () {
    return {
      dialogStore: this.$store.state.dialog,
      isEssentialsLoaded: false,
      headers: [
        { text: 'Naam', value: 'name', width: '50%' },
        { text: '', value: 'actions', sortable: false, align: 'end' }
      ],
      search: '',
      newCategory: new CategoryModel(),
      errors: new CategoryModel().getErrorEntity(),
      showNewCategory: false,
      editCategory: new CategoryModel(),
      editCategoryErrors: new CategoryModel().getErrorEntity()
    }
  },
  computed: {
    categories (): Array<CategoryModel> {
      return this.$store.getters['category/categories']
    }
  },
  beforeMount () {
    if (this.categories.length) {
      this.isEssentialsLoaded = true
    } else {
      this.loadEssentials()
    }
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      await this.$store.dispatch('category/fetchCategories')
      this.isEssentialsLoaded = true
    },
    async fetchCategories () {
      await CategoryService.getAllCategories().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((data: any) => {
            const category = new CategoryModel().fromResponse(data)
            this.categories.push(category)
          })
        }
      })
    },

    async submit () {
      if (this.newCategory.name) {
        this.errors = new CategoryModel().getErrorEntity()
        await CategoryService.createCategory(this.newCategory).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            const category = new CategoryModel().fromResponse(response.data)
            this.categories.push(category)

            this.newCategory.name = ''
            this.showNewCategory = false
          } else if (response.status === HttpStatus.BAD_REQUEST) {
            this.errors.name.push(response.data.detail)
          }
        })
      }
    },

    setEditCategory (item: CategoryModel) {
      this.editCategory.setData(item)
      item.isEdit = true
    },

    async updateCategory (old: CategoryModel) {
      this.editCategoryErrors = new CategoryModel().getErrorEntity()
      if (!this.editCategory.name) {
        this.editCategoryErrors.name.push('Naam is verplicht')
        new Notify().error('Mislukt', 'Naam is verplicht')
      } else {
        await CategoryService.updateCategory(this.editCategory).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            old.name = response.data.name
            old.isEdit = false
          }
        })
      }
    },

    async deleteCategory (item: CategoryModel) {
      const isDeleteConfirmed = await this.dialogStore.confirmDialog.open({ message: item.name + ' verwijderen?', isDeleting: true })
      if (isDeleteConfirmed) {
        await CategoryService.deleteCategory(item.id).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            const index = this.categories.findIndex(c => c.id === item.id)
            this.categories.splice(index, 1)
          }
        })
      }
    },

    showNew () {
      this.showNewCategory = true
    }
  }
})
