<template>
      <div :class="{
        'colored__state': true,
        'state--success': isOk,
        'state--error': !isOk
        }">
      </div>
</template>

<script>

export default {
  props: {
    isOk: {
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/_globals';

  .colored__state {
    width: 16px;
    height: 16px;
    border-radius: 25px;

    &.state--success {
      background-color: map-get($state-colors, 'success' )
    }
    &.state--error {
      background-color: map-get($state-colors, 'error' )
    }
  }
</style>
