







































import Vue from 'vue'
import InputField from '@/globals/components/form/InputField.vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { MechanicService } from '@/network/api/mechanic-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '@/globals/controllers/Notification'
import { UserModel } from '@/globals/models/UserModel'

export default Vue.extend({
  components: {
    InputField,
    PrimaryModal,
    PrimaryButton
  },
  data () {
    return {
      user: new UserModel(),
      errors: ''
    }
  },
  computed: {
    isSubmitDisabled (): boolean {
      return !this.user.first_name || !this.user.last_name || !this.user.email || !this.user.password
    }
  },
  methods: {
    async submitForm () {
      this.errors = ''
      if (!this.isSubmitDisabled) {
        await MechanicService.createMechanic(this.user).then((response: any) => {
          if (response.status === HttpStatus.BAD_REQUEST) {
            this.errors = response.data.detail
          }

          if (response.status === HttpStatus.OK) {
            const mechanic = new UserModel().fromResponse(response.data)
            new Notify().success('Nieuwe monteur', mechanic.first_name + ' ' + mechanic.last_name + ' is toegevoegd')
            this.$emit('created', mechanic)
          }
        })
      }
    }
  }
})
