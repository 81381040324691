












import Vue from 'vue'
export default Vue.extend({
  props: {
    maxDept: {
      type: Number,
      required: false
    },
    currentPage: {
      type: String,
      required: false
    },
    custom: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      breadcrumbs: [] as Array<object>
    }
  },
  mounted () {
    if (this.custom) {
      this.breadcrumbs = this.custom as Array<object>
    } else {
      this.setBreadcrumbs()
    }
  },
  methods: {
    setBreadcrumbs () {
      const route = this.$route
      const param = this.getParam()
      const parent = route.matched[route.matched.length - 1].parent

      this.breadcrumbs.push({ route: parent?.name, name: parent?.meta.name })
      this.breadcrumbs.push({ route: route.name, name: param ?? route.meta?.name })

      // const splittedPath = location.pathname.split('/')
      // splittedPath.shift()

      // if (this.maxDept && this.maxDept < splittedPath.length) {
      //   for (let i = 0; i < this.maxDept; i++) {
      //     splittedPath.shift()
      //   }
      // }

      // this.breadcrumbs = splittedPath
    },
    getParam (): string|null {
      return this.$route.params.id ? this.$route.params.id : null
    }
  }
})
