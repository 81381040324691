













import Vue from 'vue'
import MechanicsTable from './components/mechanics-table/MechanicsTable.vue'
import CreateMechanicModal from '../components/create-mechanic-modal/CreateMechanicModal.vue'
import { UserModel } from '@/globals/models/UserModel'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { MechanicService } from '@/network/api/mechanic-service'
import UpdateMechanicModal from '../components/update-mechanic-modal/UpdateMechanicModal.vue'

export default Vue.extend({
  components: {
    MechanicsTable,
    CreateMechanicModal,
    UpdateMechanicModal
  },
  data () {
    return {
      isClient: this.$store.getters['user/isClient'],
      isEssentialsLoaded: false,
      mechanics: [] as Array<UserModel>,
      isNewDossierModal: false,
      isCreatemechanicModal: false,
      selectedmechanic: null as null|UserModel,
      isUpdatemechanicModal: false
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      await this.getmechanics()
      this.isEssentialsLoaded = true
    },

    async getmechanics () {
      await MechanicService.getMechanics().then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          this.mechanics = []
          response.data.forEach((userRaw: any) => {
            const user = new UserModel().fromResponse(userRaw)
            this.mechanics.push(user)
          })
        }
      })
    },

    onmechanicCreated (mechanic: UserModel) {
      this.mechanics.unshift(mechanic)
      this.isCreatemechanicModal = false
    },

    openUpdateModal (mechanic: UserModel) {
      this.selectedmechanic = mechanic
      this.isUpdatemechanicModal = true
    },

    onmechanicUpdated (mechanic: UserModel) {
      const mechanicIndex = this.mechanics.findIndex(c => c.id === mechanic.id)

      if (mechanicIndex !== null) {
        this.mechanics[mechanicIndex].first_name = mechanic.first_name
        this.mechanics[mechanicIndex].last_name = mechanic.last_name
        this.mechanics[mechanicIndex].email = mechanic.email
      }

      this.isUpdatemechanicModal = false
    }
  }
})
