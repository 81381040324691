import { BaseModel } from '@/globals/models/BaseModel'
import { CategoryModel } from './CategoryModel'

export class MaterialModel extends BaseModel {
    id = 0
    name = ''
    categories = [] as Array<CategoryModel>

    constructor (data?: any) {
      super()
      this.setData(data)
    }

    setData (data?: any) {
      if (data) {
        this.id = data.id
        this.name = data.name
        this.categories = data.categories
      }
    }

    getErrorEntity () {
      const errorModel = super.generateErrorEntity(this) as any

      return errorModel
    }

    fromResponse (data: any) {
      this.setData(data)

      this.categories = []
      data.categories.forEach((categoryRaw: any) => {
        this.categories.push(new CategoryModel().fromResponse(categoryRaw))
      })

      return this
    }
}
