














import Vue from 'vue'
import InputField from '@/globals/components/form/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { AuthService } from '@/network/api/auth-service'
import { UserService } from '../../network/api/user-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { UserModel } from '../../globals/models/UserModel'
import { LoginModel } from '@/globals/models/LoginModel'
import { RouteNames } from '@/router/RouteNames'

export default Vue.extend({
  components: {
    InputField,
    PrimaryButton
  },
  data () {
    return {
      login: new LoginModel(),
      errors: new LoginModel().getErrorEntity() as any,
      errorMessage: ''
    }
  },
  methods: {
    async submitForm () {
      this.errorMessage = ''

      if (!this.isValidationError()) {
        this.loginUser()
      }
    },
    isValidationError (): boolean {
      this.errors = new LoginModel().getErrorEntity()
      let error = false

      if (!this.login.email) {
        this.errors.email.push('E-mail is verplicht.')
        error = true
      }
      if (!this.login.password) {
        this.errors.password.push('Wachtwoord is verplicht.')
        error = true
      }

      return error
    },

    async loginUser () {
      await AuthService.login(this.login).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          localStorage.setItem('access_token', response.data.access_token)
          localStorage.setItem('refresh_token', response.data.refresh_token)
          await this.getUser()
          this.navigateToProjectOverview()
        }
        if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.errorMessage = response.data.detail
        }
      })
    },

    async getUser () {
      await UserService.getUserSelf().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const user = new UserModel().fromResponse(response.data.user)
          this.$store.commit('user/setUser', user)
        }
      })
    },
    navigateToProjectOverview () {
      this.$router.push({ name: RouteNames.project.overview })
    },
    clearErrors () {
      this.errors = new LoginModel().getErrorEntity()
    }
  }
})
