var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input__field"},[(_vm.label || _vm.forceLabel)?_c('label',{style:(_vm.labelStyle),domProps:{"innerHTML":_vm._s(_vm.forceLabel ? '<br>' : _vm.label)}}):_vm._e(),_c('div',{staticClass:"form-input--group"},[(_vm.icon)?_c('i',{class:'append--icon ' + _vm.icon}):_vm._e(),(_vm.isPrice)?_c('currency-input',{class:{
        'form--input': true,
        'validation--error': _vm.errorModel.length || _vm.isError,
        'append--icon': _vm.appendIcon,
        'prepend--icon': _vm.prependIcon,
      },attrs:{"value":_vm.value,"placeholder":_vm.placeholder},on:{"input":function($event){return _vm.$emit('input', $event)}}}):_c('input',{ref:"input",class:{
        'form--input': true,
        'validation--error': _vm.errorModel.length || _vm.isError,
        'append--icon': _vm.appendIcon,
        'prepend--icon': _vm.prependIcon,
      },style:(_vm.inputStyle),attrs:{"placeholder":_vm.placeholder,"readonly":_vm.readonly,"type":_vm.inputType,"disabled":_vm.disabled,"step":_vm.step,"min":_vm.min,"max":_vm.max,"name":_vm.name,"autocomplete":_vm.type === 'password' ? 'new-password' : ''},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.handleInput($event)},"blur":function($event){return _vm.handleBlur($event)}}}),(_vm.showPassword)?_c('i',{class:{
      'prepend--icon': true,
      'password--eye': true,
      'fas fa-eye': !_vm.isPasswordShown,
      'fas fa-eye-slash': _vm.isPasswordShown
    },on:{"click":function($event){return _vm.showHidePassword()}}}):_vm._e(),(_vm.isDate)?_c('i',{class:{
      'prepend--icon': true,
      'far fa-calendar': true
    }}):_vm._e(),(_vm.isNowLoading)?_c('div',{staticClass:"loading__container"},[_c('i',{class:{
      'prepend--icon' :true,
      'fas fa-circle-notch fa-spin' :true
      }})]):_vm._e()],1),(_vm.errorModel.length && !_vm.hideErrors)?_c('ul',{staticClass:"form__validation"},_vm._l((_vm.errorModel),function(error){return _c('li',{key:error.id},[_vm._v(_vm._s(error))])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }