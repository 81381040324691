import { ProjectModel } from '@/globals/models/ProjectModel'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { CategoryService } from '@/network/api/category-service'
import { CategoryModel } from '@/globals/models/CategoryModel'

const state = () => ({
  categories: [] as Array<ProjectModel>
})

const getters = {
  categories: (state: any) => {
    return state.categories
  }
}

const actions = {
  async fetchCategories ({ commit }: any) {
    await CategoryService.getAllCategories().then((response: any) => {
      if (response.status === HttpStatus.OK) {
        const categories = [] as Array<CategoryModel>
        response.data.forEach((data: any) => {
          const category = new CategoryModel().fromResponse(data)
          categories.push(category)
        })
        commit('setCategories', categories)
      }
    })
  },
  async deleteCategory ({ commit }: any, category: CategoryModel) {
    commit('deleteCategory', category)
  }
}

const mutations = {
  setCategories (state: any, categories: Array<CategoryModel>) {
    state.categories = categories
  },
  deleteCategory (state: any, category: CategoryModel) {
    const index = state.categories.findIndex((c: CategoryModel) => c.id === category.id)
    state.categories.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
