














import Vue from 'vue'
export default Vue.extend({
  props: {
    isCloseButton: {
      type: Boolean,
      default: false
    },
    isBigCloseButton: {
      type: Boolean,
      default: false
    },
    containerStyle: {
      type: Object,
      required: false
    }
  },
  mounted () {
    const modal = this.$refs.modal as any
    modal.focus()
  }
})
