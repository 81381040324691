
































import Vue from 'vue'
import InputField from '@/globals/components/form/InputField.vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { MaterialService } from '@/network/api/material-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '@/globals/controllers/Notification'
import { MaterialModel } from '@/globals/models/MaterialModel'
import { ObjectHelper } from '@/globals/helpers/ObjectHelper'
import { CategoryModel } from '@/globals/models/CategoryModel'
import Dropdown from '@/globals/components/form/Dropdown.vue'

export default Vue.extend({
  components: {
    InputField,
    PrimaryModal,
    PrimaryButton,
    Dropdown
  },
  props: {
    _material: {
      type: MaterialModel,
      required: false
    }
  },
  data () {
    return {
      material: new MaterialModel(),
      isEdit: false,
      errors: ''
    }
  },
  computed: {
    categories (): Array<CategoryModel> {
      return this.$store.getters['category/categories']
    }
  },
  beforeMount () {
    if (this._material) {
      this.material = ObjectHelper.cloneObject(this._material)
      this.isEdit = true
    }
  },
  methods: {
    async submitForm () {
      this.errors = ''
      if (this.material.name) {
        if (this.isEdit) {
          this.updateMaterial()
        } else {
          this.createMaterial()
        }
      }
    },
    async updateMaterial () {
      await MaterialService.updateMaterial(this.material).then((response: any) => {
        if (response.status === HttpStatus.BAD_REQUEST) {
          this.errors = response.data.detail
        }

        if (response.status === HttpStatus.OK) {
          const material = new MaterialModel().fromResponse(response.data)
          new Notify().success('Materiaal aangepast naar ', material.name)
          this.$emit('updated', material)
        }
      })
    },
    async createMaterial () {
      await MaterialService.createMaterial(this.material).then((response: any) => {
        if (response.status === HttpStatus.BAD_REQUEST) {
          this.errors = response.data.detail
        }

        if (response.status === HttpStatus.OK) {
          const material = new MaterialModel().fromResponse(response.data)
          new Notify().success('Nieuw materiaal', material.name + ' is toegevoegd')
          this.$emit('created', material)
        }
      })
    }
  }
})
