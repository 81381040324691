





















































import Vue from 'vue'
import { ProjectStatusEnum } from '@/globals/enums/ProjectStatus'
import { ProjectModel } from '@/globals/models/ProjectModel'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import ColoredState from '@/globals/components/colored-state/ColoredState.vue'
import { ProjectService } from '@/network/api/project-service'
import { RouteNames } from '@/router/RouteNames'

export default Vue.extend({
  props: {
    _project: {
      type: ProjectModel,
      required: true
    }
  },
  components: {
    PrimaryButton,
    ColoredState
  },
  data () {
    return {
      isRemoveConfirmDialog: false,
      projectStatus: new ProjectStatusEnum()
    }
  },
  computed: {
    isAdmin (): boolean {
      return this.$store.getters['user/isAdmin']
    },
    isController (): boolean {
      return this.$store.getters['user/isController']
    },
    isMechanic (): boolean {
      return this.$store.getters['user/isMechanic']
    }
  },
  methods: {
    async updateStatus (status: number) {
      this._project.status = status
      await ProjectService.updateProject(this._project.id, this._project)
    },

    navigateToProjectEdit () {
      this.$router.push({ name: RouteNames.project.edit, params: { id: this._project.id.toString() } })
    }
  }
})
