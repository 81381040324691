







































import Vue from 'vue'
import InputField from '@/globals/components/form/InputField.vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '@/globals/controllers/Notification'
import { UserModel } from '@/globals/models/UserModel'
import { TypeHelper } from '@/globals/helpers/TypeHelper'
import { UserService } from '@/network/api/user-service'

export default Vue.extend({
  components: {
    InputField,
    PrimaryModal,
    PrimaryButton
  },
  props: {
    _mechanic: {
      type: UserModel,
      required: true
    }
  },
  data () {
    return {
      user: new UserModel(),
      errors: new UserModel().getErrorEntity(),
      error: ''
    }
  },
  computed: {
    isSubmitDisabled (): boolean {
      return !this.user.first_name && !this.user.last_name && !this.user.email && !this.user.password
    }
  },
  beforeMount () {
    this.user = TypeHelper.copyObject(this._mechanic)
  },
  methods: {
    async submitForm () {
      if (!this.isSubmitDisabled) {
        this.error = ''

        if (this.user.password) {
          await this.updatePassword()
        }

        if (!this.error) {
          await this.updateUser()
        }
      }
    },

    async updatePassword () {
      if (this.user.id) {
        await UserService.changeUserPassword(this.user.id, this.user.password).then((response: any) => {
          if (response.status === HttpStatus.BAD_REQUEST) {
            this.error = response.data.detail
          }
        })
      }
    },

    async updateUser () {
      await UserService.updateUser(this.user).then((response: any) => {
        if (response.status === HttpStatus.BAD_REQUEST) {
          this.error = response.data.detail
        }
        if (response.status === HttpStatus.OK) {
          const mechanic = new UserModel().fromResponse(response.data)
          new Notify().success('Monteur bijgewerkt', mechanic.first_name + ' ' + mechanic.last_name + ' is aangepast')
          this.$emit('updated', mechanic)
        }
      })
    }
  }
})
