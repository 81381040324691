import { MaterialModel } from '@/globals/models/MaterialModel'
import { client } from '../client'
import { BaseService } from './base-service'

export const MaterialService = {
  _apiEndpoint: BaseService.apiEndpoint + 'material',

  async getDefaultMaterials () {
    const url = this._apiEndpoint + '/defaults'

    return await client.get(url)
  },

  async createMaterial (material: MaterialModel) {
    const url = this._apiEndpoint

    return await client.post(url, material)
  },

  async updateMaterial (material: MaterialModel) {
    const url = this._apiEndpoint + '/' + material.id

    return await client.put(url, material)
  },

  async deleteMaterial (id: number) {
    const url = this._apiEndpoint + '/' + id

    return await client.delete(url)
  }
}
