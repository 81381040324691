import { BaseModel } from '@/globals/models/BaseModel'
import { SubjectModel } from './SubjectModel'
import { ControlDayModel } from './ControlDayModel'
import { UserModel } from './UserModel'
import { ProjectAttachmentModel } from './ProjectAttachmentModel'

export class ProjectModel extends BaseModel {
    id = 0
    address = ''
    city = ''
    ob_id = ''
    order_id = ''
    note = ''
    controller = new UserModel()
    controller_user_id = null
    status = 0
    created_at = new Date()
    subjects = [] as Array<SubjectModel>
    control_days = [] as Array<ControlDayModel>
    attachments = [] as Array<ProjectAttachmentModel>
    mechanics = [] as Array<any>
    mechanics_ids = [] as Array<{ user_id: number }>

    constructor (data?: any) {
      super()
      this.setData(data)
    }

    setData (data?: any) {
      if (data) {
        this.id = data.id
        this.address = data.address
        this.city = data.city
        this.ob_id = data.ob_id
        this.order_id = data.order_id
        this.controller = data.controller
        this.note = data.note
        this.controller_user_id = data.controller_user_id
        this.status = data.status
        this.created_at = data.created_at
        this.subjects = data.subjects
        this.attachments = data.attachments
        this.mechanics = data.mechanics
      }
    }

    getErrorEntity () {
      const errorModel = super.generateErrorEntity(this) as any

      return errorModel
    }

    fromResponse (data: any): ProjectModel {
      this.setData(data)

      this.created_at = new Date(data.created_at)
      this.controller = new UserModel().fromResponse(data.controller)

      this.subjects = []
      data.subjects.forEach((subjectRaw: any) => {
        const subject = new SubjectModel().fromResponse(subjectRaw)
        this.subjects.push(subject)
      })

      this.control_days = []
      data.control_days.reverse().forEach((controlDayRaw: any) => {
        const controlDay = new ControlDayModel().fromResponse(controlDayRaw)
        this.control_days.push(controlDay)
      })

      this.attachments = []
      data.attachments.forEach((attachmentRaw: any) => {
        const attachment = new ProjectAttachmentModel().fromResponse(attachmentRaw)
        this.attachments.push(attachment)
      })

      this.mechanics = []
      data.mechanics.forEach((mechanicRaw: any) => {
        this.mechanics.push(mechanicRaw)
        this.mechanics_ids.push(mechanicRaw.user_id)
      })

      return this
    }

    isSuccess (): boolean {
      let success = false

      if (this.control_days.length) {
        success = this.control_days[0].isSuccess()
      }

      return success
    }

    isDuplicated (): boolean {
      return (this.ob_id == null || this.order_id == null) && this.controller_user_id === null
    }

    isControlled (): boolean {
      return this.control_days.length > 0
    }
}
