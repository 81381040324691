import { TaskModel } from '@/globals/models/TaskModel'
import { client } from '../client'
import { BaseService } from './base-service'

export const TaskService = {
  _apiEndpoint: BaseService.apiEndpoint + 'task',

  async getDefaultTasks () {
    const url = this._apiEndpoint + '/defaults'

    return await client.get(url)
  },

  async createTask (task: TaskModel) {
    const url = this._apiEndpoint

    return await client.post(url, task)
  },

  async updateTask (task: TaskModel) {
    const url = this._apiEndpoint + '/' + task.id

    return await client.put(url, task)
  },

  async deleteTask (id: number) {
    const url = this._apiEndpoint + '/' + id

    return await client.delete(url)
  }
}
