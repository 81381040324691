import { CategoryModel } from '@/globals/models/CategoryModel'
import { client } from '../client'
import { BaseService } from './base-service'

export const CategoryService = {
  _apiEndpoint: BaseService.apiEndpoint + 'category',

  async getAllCategories () {
    const url = this._apiEndpoint + '/all'

    return await client.get(url)
  },

  async createCategory (category: CategoryModel) {
    const url = this._apiEndpoint

    return await client.post(url, category)
  },

  async updateCategory (category: CategoryModel) {
    const url = this._apiEndpoint + '/' + category.id

    return await client.put(url, category)
  },

  async deleteCategory (id: number) {
    const url = this._apiEndpoint + '/' + id

    return await client.delete(url)
  }
}
