

















































import Vue from 'vue'
import { ControlModel } from '@/globals/models/ControlModel'
import { ProjectModel } from '@/globals/models/ProjectModel'
import { TaskModel } from '@/globals/models/TaskModel'
import { SubjectModel } from '@/globals/models/SubjectModel'
import { MaterialModel } from '@/globals/models/MaterialModel'
import { AttachmentModel } from '@/globals/models/AttachmentModel'
import FullPhotoView from '../full-photo-view/FullPhotoView.vue'
import ActionControl from './action-control/ActionControl.vue'

export default Vue.extend({
  props: {
    project: {
      type: ProjectModel,
      required: true
    }
  },
  components: {
    FullPhotoView,
    ActionControl
  },
  data () {
    return {
      isEssentialsLoaded: false,
      isAdmin: this.$store.getters['user/isAdmin'],
      isFullModalActive: false,
      selectedAttachment: null as AttachmentModel|null,
      selectedGallery: [] as Array<AttachmentModel>
    }
  },
  computed: {
    controls (): Array<ControlModel> {
      return this.project.control_days.length ? this.project.control_days[0].controls : []
    },
    subjectControls (): Array<object> {
      const subjectControls = [] as Array<object>

      this.project.subjects.forEach((subject: SubjectModel) => {
        const controls = this.getControls(subject)

        if (controls.tasks.length || controls.materials.length) {
          const subjectControl = { subject: subject, taskControls: controls.tasks, materialControls: controls.materials }
          subjectControls.push(subjectControl)
        }
      })

      return subjectControls
    }
  },
  methods: {
    getControls (subject: SubjectModel): { tasks: Array<{ task: TaskModel; control: ControlModel }>; materials: Array<{ material: MaterialModel; control: ControlModel }> } {
      const tasks = [] as Array<{ task: TaskModel; control: ControlModel }>
      const materials = [] as Array<{ material: MaterialModel; control: ControlModel }>

      subject.tasks.forEach((task: TaskModel) => {
        const control = this.getControlByTaskId(task.id)

        if (control && !control.status) {
          tasks.push({ task: task, control: control })
        }
      })

      subject.materials.forEach((material: MaterialModel) => {
        const control = this.getControlByMaterialId(material.id)

        if (control && !control.status) {
          materials.push({ material: material, control: control })
        }
      })

      return { tasks: tasks, materials: materials }
    },
    getControlByTaskId (taskId: number): ControlModel|null {
      const control = this.controls.find(c => c.task_id === taskId)
      return control ?? null
    },
    getControlByMaterialId (materialId: number): ControlModel|null {
      const control = this.controls.find(c => c.material_id === materialId)
      return control ?? null
    },
    toFullImage (attachment: AttachmentModel, gallery: Array<AttachmentModel>) {
      this.selectedGallery = gallery
      this.selectedAttachment = attachment
      this.isFullModalActive = true
    }
  }
})
