import { BaseModel } from '@/globals/models/BaseModel'
import { ControlModel } from './ControlModel'
import { DateHelper } from '../helpers/DateHelper'

export class ControlDayModel extends BaseModel {
  id = 0
  project_id = 0
  date = DateHelper.getNewDate()
  controls = [] as Array<ControlModel>

  constructor (data?: any) {
    super()
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.project_id = data.project_id
      this.date = data.date
    }
  }

  getErrorEntity () {
    const errorModel = super.generateErrorEntity(this) as any

    return errorModel
  }

  fromResponse (data: any) {
    this.setData(data)

    this.date = new Date(data.date)

    this.controls = []
    data.controls.forEach((controlRaw: any) => {
      const control = new ControlModel().fromResponse(controlRaw)
      this.controls.push(control)
    })

    return this
  }

  isControlled (): boolean {
    let isControlled = false

    for (let i = 0; i < this.controls.length; i++) {
      if (this.controls[i].id) {
        isControlled = true
        break
      }
    }
    return isControlled
  }

  isSuccess (): boolean {
    let success = false
    let successCount = 0

    this.controls.forEach((control: ControlModel) => {
      if (control.id && control.status) {
        successCount += 1
      }
    })

    // check if all controls are empty
    if (this.controls.length && successCount === this.controls.length) {
      success = true
    }

    return success
  }
}
