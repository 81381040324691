import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { RouteNames } from './RouteNames'
import store from '@/store/index'
import { RoleEnum } from '@/globals/enums/RoleEnum'

import Login from '../views/login/Login.vue'
import Project from '../views/project/Project.vue'
import ProjectOverview from '../views/project/overview/Overview.vue'
import ProjectDetail from '../views/project/detail/Detail.vue'
import ProjectCreate from '../views/project/create/Create.vue'
import ProjectEdit from '../views/project/edit/Edit.vue'
import Subject from '../views/subject/Subject.vue'
import SubjectOverview from '../views/subject/overview/Overview.vue'
import Task from '../views/task/Task.vue'
import TaskOverview from '../views/task/overview/Overview.vue'
import Material from '../views/material/Material.vue'
import MaterialOverview from '../views/material/overview/Overview.vue'
import Controller from '../views/controller/Controller.vue'
import ControllerOverview from '../views/controller/overview/Overview.vue'
import Mechanic from '../views/mechanic/Mechanic.vue'
import MechanicOverview from '../views/mechanic/overview/Overview.vue'
import Settings from '../views/settings/Settings.vue'
import SettingsChangePassword from '../views/settings/change-password/ChangePassword.vue'

Vue.use(VueRouter)

const routeNames = RouteNames
const globalStore = store.state as any

const routes: Array<RouteConfig> = [
  /**
   * Start
   */
  {
    path: '/',
    redirect: { name: routeNames.project.overview }
  },

  /**
   * Auth
   */
  {
    path: '/login',
    name: routeNames.login.login,
    component: Login
  },

  /**
   * Project
   */
  {
    path: '/projects',
    name: routeNames.project.base,
    component: Project,
    redirect: { name: routeNames.project.overview },
    meta: {
      name: 'Projecten overzicht'
    },
    children: [
      {
        path: '',
        name: routeNames.project.overview,
        component: ProjectOverview
      },
      {
        path: 'create',
        name: routeNames.project.create,
        component: ProjectCreate,
        beforeEnter: (to: any, from: any, next: any) => {
          const isAdmin = globalStore.user.role === RoleEnum.Admin
          if (isAdmin) {
            next()
          } else {
            next({ name: routeNames.project.overview })
          }
        }
      },
      {
        path: ':id',
        name: routeNames.project.detail,
        component: ProjectDetail
      },
      {
        path: ':id/edit',
        name: routeNames.project.edit,
        component: ProjectEdit,
        beforeEnter: (to: any, from: any, next: any) => {
          const isAdmin = globalStore.user.role === RoleEnum.Admin
          if (isAdmin) {
            next()
          } else {
            next({ name: routeNames.project.overview })
          }
        }
      }
    ]
  },

  /**
   * Subject
   */
  {
    path: '/subjects',
    name: routeNames.subject.base,
    component: Subject,
    redirect: { name: routeNames.subject.overview },
    beforeEnter: (to: any, from: any, next: any) => {
      const isAdmin = globalStore.user.role === RoleEnum.Admin
      if (isAdmin) {
        next()
      } else {
        next({ name: routeNames.project.overview })
      }
    },
    children: [
      {
        path: '',
        name: routeNames.subject.overview,
        component: SubjectOverview
      }
    ]
  },

  /**
   * Task
   */
  {
    path: '/tasks',
    name: routeNames.task.base,
    component: Task,
    redirect: { name: routeNames.subject.overview },
    beforeEnter: (to: any, from: any, next: any) => {
      const isAdmin = globalStore.user.role === RoleEnum.Admin
      if (isAdmin) {
        next()
      } else {
        next({ name: routeNames.project.overview })
      }
    },
    children: [
      {
        path: '',
        name: routeNames.task.overview,
        component: TaskOverview
      }
    ]
  },

  /**
   * Material
   */
  {
    path: '/materials',
    name: routeNames.material.base,
    component: Material,
    redirect: { name: routeNames.material.overview },
    beforeEnter: (to: any, from: any, next: any) => {
      const isAdmin = globalStore.user.role === RoleEnum.Admin
      if (isAdmin) {
        next()
      } else {
        next({ name: routeNames.project.overview })
      }
    },
    children: [
      {
        path: '',
        name: routeNames.material.overview,
        component: MaterialOverview
      }
    ]
  },

  /**
   * Controller
   */
  {
    path: '/controllers',
    name: routeNames.controller.base,
    component: Controller,
    redirect: { name: routeNames.controller.overview },
    beforeEnter: (to: any, from: any, next: any) => {
      const isAdmin = globalStore.user.role === RoleEnum.Admin
      if (isAdmin) {
        next()
      } else {
        next({ name: routeNames.project.overview })
      }
    },
    children: [
      {
        path: '',
        name: routeNames.controller.overview,
        component: ControllerOverview
      }
    ]
  },

  /**
   * Mechanic
   */
  {
    path: '/mechanics',
    name: routeNames.mechanic.base,
    component: Mechanic,
    redirect: { name: routeNames.mechanic.overview },
    beforeEnter: (to: any, from: any, next: any) => {
      const isAdmin = globalStore.user.role === RoleEnum.Admin
      if (isAdmin) {
        next()
      } else {
        next({ name: routeNames.project.overview })
      }
    },
    children: [
      {
        path: '',
        name: routeNames.mechanic.overview,
        component: MechanicOverview
      }
    ]
  },

  /**
   * Settings
   */
  {
    path: '/settings',
    name: routeNames.settings.base,
    component: Settings,
    redirect: { name: routeNames.settings.change_password },
    children: [
      {
        path: '',
        name: routeNames.settings.change_password,
        component: SettingsChangePassword
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function isAuthenticated (): boolean {
  return localStorage.getItem('access_token') !== null
}

router.beforeEach((to, from, next) => {
  if (!to.path.startsWith('/login') && !isAuthenticated()) {
    next({ name: 'login' })
  } else if (to.path.startsWith('/login') && isAuthenticated()) {
    next({ name: RouteNames.project.overview })
  } else {
    next()
  }
})

export default router
