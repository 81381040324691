


















































































import Vue from 'vue'
import { ProjectModel } from '@/globals/models/ProjectModel'
import { RouteNames } from '@/router/RouteNames'
import InputField from '@/globals/components/form/InputField.vue'
import Dropdown from '@/globals/components/form/Dropdown.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { ProjectService } from '@/network/api/project-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { SubjectModel } from '@/globals/models/SubjectModel'
import { ControllerService } from '@/network/api/controller-service'
import { UserModel } from '@/globals/models/UserModel'
import { Notify } from '@/globals/controllers/Notification'
import { SubjectService } from '@/network/api/subject-service'
import { MechanicService } from '@/network/api/mechanic-service'

export default Vue.extend({
  components: {
    InputField,
    Dropdown,
    PrimaryButton
  },
  data () {
    return {
      isClient: this.$store.getters['user/isClient'],
      project: new ProjectModel(),
      errors: '',
      isEssentialsLoaded: false,
      subjects: [] as Array<SubjectModel>,
      controllers: [] as Array<{ value: number; text: string }>,
      mechanics: [] as Array<{ value: number; text: string }>
    }
  },
  computed: {
    submitDisabled (): boolean {
      let isDisabled = !this.project.order_id || !this.project.ob_id || !this.project.address || !this.project.city || !this.project.subjects.length || !this.project.mechanics.length || !this.project.controller_user_id as boolean

      if (!this.project.order_id && this.project.ob_id) {
        isDisabled = false
      }
      if (this.project.order_id && !this.project.ob_id) {
        isDisabled = false
      }
      return isDisabled
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      await this.fetchDefaultSubjects()
      await this.fetchControllers()
      await this.fetchMechanics()
      this.isEssentialsLoaded = true
    },
    async fetchDefaultSubjects () {
      await SubjectService.getDefaultSubjects().then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((subjectRaw: any) => {
            const subject = new SubjectModel().fromResponse(subjectRaw)
            this.subjects.push(subject)
          })
        }
      })
    },
    async fetchControllers () {
      await ControllerService.getControllers().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((controllerRaw: UserModel) => {
            const controller = new UserModel().fromResponse(controllerRaw)
            if (controller.id) {
              this.controllers.push({ value: controller.id, text: controller.getFullName() })
            }
          })
        }
      })
    },
    async fetchMechanics () {
      await MechanicService.getMechanics().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((mechanicRaw: UserModel) => {
            const mechanic = new UserModel().fromResponse(mechanicRaw)
            if (mechanic.id) {
              this.mechanics.push({ value: mechanic.id, text: mechanic.getFullName() })
            }
          })
        }
      })
    },

    async submitForm () {
      this.errors = ''

      if (!this.submitDisabled) {
        await this.createProject()
      }
    },

    async createProject () {
      await ProjectService.createProject(this.project).then((response: any) => {
        if (response.status === HttpStatus.BAD_REQUEST) {
          this.errors = response.data.detail
        }

        if (response.status === HttpStatus.OK) {
          const project = new ProjectModel().fromResponse(response.data)
          new Notify().success('Project is aangemaakt', '')
          this.navigateToEditProject(project.id)
        }
      })
    },

    navigateToEditProject (id: number) {
      this.$router.push({ name: RouteNames.project.detail, params: { id: id.toString() } })
    },

    navigateToProjectOverview () {
      this.$router.push({ name: RouteNames.project.overview })
    }
  }
})
