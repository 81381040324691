import { BaseModel } from '@/globals/models/BaseModel'

export class LoginModel extends BaseModel {
    email = ''
    password = ''

    getErrorEntity () {
      return super.generateErrorEntity(this)
    }
}
