














import Vue from 'vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'

export default Vue.extend({
  components: {
    PrimaryModal
  },
  props: {
    url: {
      type: String,
      required: true
    },
    _gallery: {
      type: Array,
      required: false
    },
    galleryUrlKey: {
      type: String,
      required: false,
      default: 'url'
    },
    isMultiple: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      currentUrl: this.url
    }
  },
  computed: {
    gallery (): Array<any> {
      return this._gallery
    },
    previousGalleryPhoto (): string {
      if (this.isMultiple) {
        const index = this.getGalleryIndexByKey(this.currentUrl)

        if (index !== null && index > 0) {
          return this.gallery[index - 1][this.galleryUrlKey].toString()
        }
      }

      return ''
    },
    nextGalleryPhoto (): string {
      if (this.isMultiple) {
        const index = this.getGalleryIndexByKey(this.currentUrl)

        if (index !== null && index < this.gallery.length - 1) {
          return this.gallery[index + 1][this.galleryUrlKey].toString()
        }
      }

      return ''
    }
  },
  methods: {
    getGalleryIndexByKey (url: string): number|null {
      const gallery = this.gallery as Array<any>
      const index = gallery.findIndex(c => c[this.galleryUrlKey] === url)
      return index ?? null
    },
    goPreviousItem () {
      if (this.previousGalleryPhoto) {
        this.currentUrl = this.previousGalleryPhoto
      }
    },
    goNextItem () {
      if (this.nextGalleryPhoto) {
        this.currentUrl = this.nextGalleryPhoto
      }
    }
  }
})
