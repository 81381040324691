import { BaseModel } from '@/globals/models/BaseModel'

export class CategoryModel extends BaseModel {
    id = 0
    name = ''
    isEdit = false // used for datatable

    constructor (data?: any) {
      super()
      this.setData(data)
    }

    setData (data?: any) {
      if (data) {
        this.id = data.id
        this.name = data.name
      }
    }

    getErrorEntity () {
      const errorModel = super.generateErrorEntity(this) as any
      return errorModel
    }

    fromResponse (data: any) {
      this.setData(data)

      return this
    }
}
