export const TypeHelper = {
  stringToBool (strValue: string): boolean {
    return strValue === 'true'
  },
  numToBool (numValue: number): boolean {
    return numValue === 1
  },
  copyObject (jep: any) {
    return JSON.parse(JSON.stringify(jep))
  }
}
