import { BaseModel } from '@/globals/models/BaseModel'
import { RoleEnum } from '../enums/RoleEnum'

export class UserModel extends BaseModel {
    id = null as null|number
    email = ''
    first_name = ''
    last_name = ''
    password = ''
    role = RoleEnum.Admin

    setData (data?: any) {
      if (data) {
        this.id = data.id
        this.email = data.email
        this.first_name = data.first_name
        this.last_name = data.last_name
        this.role = data.role
      }
    }

    getErrorEntity () {
      return super.generateErrorEntity(this)
    }

    fromResponse (data: any) {
      this.setData(data)

      return this
    }

    getFullName (): string {
      return this.first_name + ' ' + this.last_name
    }
}
