


















































































import { RouteNames } from '@/router/RouteNames'
import Vue from 'vue'

export default Vue.extend({
  components: {
  },
  data () {
    return {
      userStore: this.$store.state.user,
      isAdmin: this.$store.getters['user/isAdmin'],
      isActive: false,
      routeNames: RouteNames
    }
  },
  methods: {
    navigateToSettings () {
      this.$router.replace({ name: RouteNames.settings.change_password })
    },
    logout () {
      localStorage.clear()
      this.$router.replace({ name: 'login' })
    }
  }
})
