









































import Vue from 'vue'
import { UserService } from '@/network/api/user-service'
import InputField from '@/globals/components/form/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '@/globals/controllers/Notification'

export default Vue.extend({
  components: { InputField, PrimaryButton },
  data () {
    return {
      firstName: this.$store.state.user.firstName,
      lastName: this.$store.state.user.lastName,
      email: this.$store.state.user.email,
      oldPassword: '',
      newPassword: '',
      errors: ''
    }
  },
  methods: {
    async submitForm () {
      this.errors = ''

      if (this.oldPassword && this.newPassword) {
        await this.updatePassword()
      }
    },

    async updatePassword () {
      await UserService.changePassword(this.oldPassword, this.newPassword).then((response: any) => {
        if (response.status === HttpStatus.BAD_REQUEST) {
          this.errors = response.data.detail
        }

        if (response.status === HttpStatus.OK) {
          this.oldPassword = ''
          this.newPassword = ''
          new Notify().success('Wachtwoord is aangepast', '')
        }
      })
    }
  }
})
