












import Vue from 'vue'

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
    _styles: {
      type: Object,
      required: false
    },
    backgroundGrey: {
      type: Boolean,
      required: false
    }
  }
})
