






































import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import InputField from '@/globals/components/form/InputField.vue'
import { DateHelper } from '@/globals/helpers/DateHelper'

export default Vue.extend({
  props: {
    _subjects: {
      required: true,
      default: () => []
    }
  },
  components: {
    InputField,
    PrimaryButton
  },
  data () {
    return {
      data: [] as Array<any>,
      dateHelper: DateHelper,
      headers: [
        { text: 'Naam', value: 'name' },
        { text: 'Taken', value: 'tasks' },
        { text: 'Materialen', value: 'materials' },
        { text: '', value: 'actions', sortable: false, width: 250, align: 'end' }
      ],
      search: '',
      isFilterActive: false,
      selectedFilterClient: null as null|number,
      selectedFilterStatus: null as null|number,
      isNewDossierModal: false
    }
  }
})
