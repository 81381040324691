









































import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import InputField from '@/globals/components/form/InputField.vue'
import { ProjectModel } from '@/globals/models/ProjectModel'
import Dropdown from '@/globals/components/form/Dropdown.vue'
import { DateHelper } from '@/globals/helpers/DateHelper'
import ColoredState from '@/globals/components/colored-state/ColoredState.vue'
import { RouteNames } from '@/router/RouteNames'
import { ProjectService } from '@/network/api/project-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'

export default Vue.extend({
  props: {
    _projects: {
      required: true,
      default: () => []
    }
  },
  components: {
    InputField,
    PrimaryButton,
    Dropdown,
    ColoredState
  },
  data () {
    return {
      projectStore: this.$store.state.project,
      isAdmin: this.$store.getters['user/isAdmin'],
      isClient: this.$store.getters['user/isClient'],
      data: [] as Array<any>,
      dateHelper: DateHelper,
      headers: [
        { text: 'W-NUMMER', value: 'order_id' },
        { text: 'OB-NUMMER', value: 'ob_id' },
        { text: 'ADRES', value: 'address' },
        { text: 'PLAATS', value: 'city' },
        { text: 'AANGEMAAKT', value: 'created_at' },
        { text: 'STATUS', value: 'status' },
        { text: '', value: 'actions', sortable: false, width: 125 }
      ],
      search: '',
      isFilterActive: false,
      selectedFilterClient: null as null|number,
      selectedFilterStatus: null as null|number,
      isNewDossierModal: false
    }
  },
  beforeMount () {
    if (this.isAdmin) {
      this.headers.splice(4, 0, { text: 'CONTROLEUR', value: 'controllerName' })
    }
    this.setData()
  },
  watch: {
    _projects () {
      this.data = []
      this.setData()
    }
  },
  methods: {
    setData () {
      this._projects.forEach((dossier: ProjectModel) => {
        this.addTableItem(dossier)
      })
    },
    addTableItem (project: ProjectModel) {
      const dataItem = {
        id: project.id,
        order_id: project.order_id,
        ob_id: project.ob_id,
        address: project.address,
        city: project.city,
        created_at: project.id,
        created_at_locale: this.dateHelper.toLocaleDateString(project.created_at),
        status: project.status,
        controllerName: project.controller ? project.controller.first_name + ' ' + project.controller.last_name : ''
      }
      this.data.push(dataItem)
    },

    navigateToCreateProject () {
      this.$router.push({ name: RouteNames.project.create })
    },

    clickedRow (id: number) {
      const project = (this._projects as Array<ProjectModel>).find(project => project.id === id)

      if (project) {
        if (project.isDuplicated()) {
          this.navigateToEditProject(id)
        } else {
          this.navigateToProject(id)
        }
      }
    },
    navigateToProject (id: number) {
      this.$router.push({ name: RouteNames.project.detail, params: { id: id.toString() } })
    },
    navigateToEditProject (id: number) {
      this.projectStore.project = null
      this.$router.push({ name: RouteNames.project.edit, params: { id: id.toString() } })
    },

    async duplicateProject (id: number) {
      await ProjectService.duplicateProject(id).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          this.navigateToEditProject(response.data.id)
        }
      })
    }
  }
})
