import { client } from '../client'
import { BaseService } from './base-service'
import { ControlControlModel } from '@/globals/models/ControlControlModel'

export const ControlControlService = {
  _apiEndpoint: BaseService.apiEndpoint + 'control/',

  async createControlControl (controlId: number, control: ControlControlModel) {
    const url = this._apiEndpoint + controlId + '/control'

    return await client.post(url, control)
  },

  async updateControlControl (controlId: number, control: ControlControlModel) {
    const url = this._apiEndpoint + controlId + '/control'

    return await client.put(url, control)
  },

  async createControlControlAttachment (controlId: number, controlControlId: number, files: any) {
    const url = this._apiEndpoint + controlId + '/control/' + controlControlId + '/attachment'
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } }

    return await client.post(url, files, false, headers)
  },

  async deleteControlControlAttachment (controlId: number, controlControlId: number, attachmentId: number) {
    const url = this._apiEndpoint + controlId + '/control/' + controlControlId + '/attachment/' + attachmentId

    return await client.delete(url)
  }
}
