


















import Vue from 'vue'
import TasksTable from './components/tasks-table/TasksTable.vue'
import TaskModal from '../components/task-modal/TaskModal.vue'
import { TaskModel } from '@/globals/models/TaskModel'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { TaskService } from '@/network/api/task-service'
import { Notify } from '@/globals/controllers/Notification'
import CategoryModal from '@/globals/components/category-modal/CategoryModal.vue'

export default Vue.extend({
  components: {
    TasksTable,
    TaskModal,
    CategoryModal
  },
  data () {
    return {
      isClient: this.$store.getters['user/isClient'],
      isEssentialsLoaded: false,
      tasks: [] as Array<TaskModel>,
      isNewDossierModal: false,
      isTaskModal: false,
      isCategoryModal: false,
      selectedTask: null as TaskModel|null
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      await this.getTasks()
      await this.$store.dispatch('category/fetchCategories')
      this.isEssentialsLoaded = true
    },

    async getTasks () {
      await TaskService.getDefaultTasks().then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((taskRaw: any) => {
            const task = new TaskModel().fromResponse(taskRaw)
            this.tasks.push(task)
          })
        }
      })
    },

    onTaskCreated (task: TaskModel) {
      this.tasks.unshift(task)
      this.isTaskModal = false
    },

    onTaskUpdated (updatedTask: TaskModel) {
      const index = this.tasks.findIndex(t => t.id === updatedTask.id)

      if (index >= 0) {
        this.tasks.splice(index, 1, updatedTask)
      }

      this.selectedTask = null
      this.isTaskModal = false
    },

    async deleteTask (taskId: number) {
      await TaskService.deleteTask(taskId).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const task = this.tasks.find(t => t.id === taskId)

          if (task) {
            const index = this.tasks.indexOf(task)
            new Notify().success('Verwijderd', task?.name + ' is verwijderd')
            this.tasks.splice(index, 1)
          }
        }
      })
    }
  }
})
