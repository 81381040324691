








































import Vue from 'vue'
import ColoredState from '@/globals/components/colored-state/ColoredState.vue'
import { DateHelper } from '@/globals/helpers/DateHelper'
import { ControlDayModel } from '@/globals/models/ControlDayModel'
import { ProjectModel } from '@/globals/models/ProjectModel'
import { ControlModel } from '@/globals/models/ControlModel'
import { TypeHelper } from '@/globals/helpers/TypeHelper'
import { AttachmentModel } from '@/globals/models/AttachmentModel'
import SubjectCard from './subject-card/SubjectCard.vue'
import TextButton from '@/globals/components/buttons/TextButton.vue'
import { ProjectService } from '@/network/api/project-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'

export default Vue.extend({
  props: {
    project: {
      type: ProjectModel,
      required: true
    },
    controlDay: {
      type: ControlDayModel,
      required: true
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    ColoredState,
    SubjectCard,
    TextButton
  },
  data () {
    return {
      show: true,
      isEditable: false,
      dialogStore: this.$store.state.dialog
    }
  },
  computed: {
    isSuccess (): boolean {
      let success = false
      let successCount = 0
      this.controlDay.controls.forEach((control: ControlModel) => {
        if (control.status) {
          successCount += 1
        }
      })

      // check if all controls are empty
      if (this.controlDay.controls.length && successCount === this.controlDay.controls.length) {
        success = true
      }

      return success
    },
    isDeleteEnabled (): boolean {
      return this.controlDay.id === this.project.control_days[0].id
    }
  },
  beforeMount () {
    this.show = this.isActive
  },
  methods: {
    getControlDayDateTime () {
      return DateHelper.toLocaleDateString(this.controlDay.date) + ' om ' + DateHelper.getTimeFromDateTime(this.controlDay.date)
    },

    getNoteByTaskId (taskId: number) {
      const control = this.getControlByTaskId(taskId)

      if (control) {
        return control.note
      } else {
        return ''
      }
    },
    getStatusByTaskId (taskId: number) {
      const control = this.getControlByTaskId(taskId)

      if (control) {
        return TypeHelper.numToBool(control.status)
      } else {
        return null
      }
    },
    getAttachmentsByTaskId (taskId: number): Array<AttachmentModel> {
      const control = this.getControlByTaskId(taskId)

      if (control) {
        return control.attachments
      } else {
        return []
      }
    },
    getControlByTaskId (taskId: number): ControlModel|null {
      const control = this.controlDay.controls.find(c => c.task_id === taskId)
      return control ?? null
    },

    getNoteByMaterialId (materialId: number) {
      const control = this.getControlByMaterialId(materialId)

      if (control) {
        return control.note
      } else {
        return ''
      }
    },
    getStatusByMaterialId (materialId: number) {
      const control = this.getControlByMaterialId(materialId)

      if (control) {
        return control.status
      } else {
        return null
      }
    },
    getAttachmentsByMaterialId (materialId: number): Array<AttachmentModel> {
      const control = this.getControlByMaterialId(materialId)

      if (control) {
        return control.attachments
      } else {
        return []
      }
    },
    getControlByMaterialId (materialId: number): ControlModel|null {
      const control = this.controlDay.controls.find(c => c.material_id === materialId)
      return control ?? null
    },

    async deleteControlDay () {
      const isDeleteConfirmed = await this.dialogStore.confirmDialog.open({ message: 'Weet je zeker dat je deze controle wil verwijderen?', isDeleting: true })

      if (isDeleteConfirmed) {
        await ProjectService.deleteControlDay(this.project.id, this.controlDay.id).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            this.$emit('deleted_controlday')
          }
        })
      }
    }
  }
})
