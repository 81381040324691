import { client } from '../client'
import { BaseService } from './base-service'
import { ProjectModel } from '@/globals/models/ProjectModel'
import { ControlModel } from '@/globals/models/ControlModel'
import { ControlDayModel } from '../../globals/models/ControlDayModel'

export const ProjectService = {
  _apiEndpointProjects: BaseService.apiEndpoint + 'projects/',
  _apiEndpointProject: BaseService.apiEndpoint + 'project/',

  async getDefaultProject () {
    const url = this._apiEndpointProjects + 1

    return await client.get(url)
  },

  async getProjects () {
    const url = BaseService.apiEndpoint + 'projects'

    return await client.get(url)
  },

  async createProject (data: any) {
    const url = BaseService.apiEndpoint + 'projects'

    return await client.post(url, data)
  },

  async deleteProject (id: number|string) {
    const url = this._apiEndpointProjects + id

    return await client.delete(url)
  },

  async getProject (id: number|string) {
    const url = this._apiEndpointProjects + id

    return await client.get(url)
  },

  async updateProject (id: number|string, project: ProjectModel) {
    const url = this._apiEndpointProjects + id

    return await client.put(url, project)
  },

  async duplicateProject (id: number) {
    const url = this._apiEndpointProjects + id + '/duplicate'

    return await client.post(url)
  },

  async createProjectAttachment (projectId: number, files: any) {
    const url = this._apiEndpointProject + projectId + '/attachment'
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } }

    return await client.post(url, files, false, headers)
  },

  async deleteProjectAttachment (projectId: number, attachmentId: number) {
    const url = this._apiEndpointProject + projectId + '/attachment/' + attachmentId

    return await client.delete(url)
  },

  async createControlDay (projectId: number|string, controlDay: ControlDayModel) {
    const url = this._apiEndpointProject + projectId + '/controlday'

    return await client.post(url, controlDay)
  },

  async deleteControlDay (projectId: number, controlDayId: number) {
    const url = this._apiEndpointProject + projectId + '/controlday/' + controlDayId

    return await client.delete(url)
  },

  async createControl (projectId: number, control: any) {
    const url = this._apiEndpointProject + projectId + '/control'

    return await client.post(url, control)
  },

  async updateControl (projectId: number, control: ControlModel) {
    const url = this._apiEndpointProject + projectId + '/control'

    return await client.put(url, control)
  },

  async createControlAttachment (projectId: number, controlId: number, files: any) {
    const url = this._apiEndpointProject + projectId + '/control/' + controlId
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } }

    return await client.post(url, files, false, headers)
  },

  async deleteControlAttachment (projectId: number, controlId: number, attachmentId: number) {
    const url = this._apiEndpointProject + projectId + '/control/' + controlId + '/attachment/' + attachmentId

    return await client.delete(url)
  }
}
